import React, { useState } from "react";
import cn from "classnames";
import styles from "./Article.module.sass";
import Share from "../../../components/Share";
import { Link } from "react-router-dom";
import LikeButton from "../../../components/LikeButton";
import UnlikeButton from "../../../components/UnlikeButton";

const Article = ({ article }) => {
  const [isLiked, setIsLiked] = useState(false);
  const [isUnliked, setIsUnliked] = useState(false);
  const handleLike = () => {
    setIsLiked(!isLiked);
    if (!isLiked) {
      setIsUnliked(false);
    }
  };

  const handleUnlike = () => {
    setIsUnliked(!isUnliked);
    if (!isUnliked) {
      setIsLiked(false);
    }
  };

  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.content}>
          {article?.sections?.map((section, index) => (
            <div key={index} className={styles.sectionSpacing}>
              <h3>{section.title}</h3>
              {section.descriptions.map((description, descIndex) => (
                <p key={descIndex}>{description}</p>
              ))}
              {section.image && (
                <figure>
                  <img src={section.image} alt="content" />
                </figure>
              )}
            </div>
          ))}

          <div className={styles.actions}>
            <Share openUp />
            <Link
              to="/contact"
              className={cn("button-stroke button-small", styles.buttonContact)}
            >
              Contact Us
            </Link>
            <LikeButton
              className={styles.favorite}
              active={isLiked}
              onClick={handleLike}
            />
            <UnlikeButton
              className={styles.favorite}
              active={isUnliked}
              onClick={handleUnlike}
            />
          </div>
          {article.authors && article.authors.length > 0 && (
            <div className={styles.sectionSpacing}>
              <h3>Authors</h3>
              {article.authors.map((author, index) => (
                <p key={index}>{author.name}</p>
              ))}
            </div>
          )}

          {article.acknowledgements && (
            <div className={styles.sectionSpacing}>
              <h3>Acknowledgements</h3>
              <p>{article.acknowledgements}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Article;
