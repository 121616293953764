import React, { useRef } from "react";
import Main from "./Main";
import Categories from ".//Categories";
import Newsletter from "./Newsletter";

const Developers = () => {
  const scrollToRef = useRef(null);

  return (
    <>
      <Main scrollToRef={scrollToRef} />
      {/* <Categories /> */}
      <Newsletter scrollToRef={scrollToRef} />
    </>
  );
};

export default Developers;
