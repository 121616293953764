import cn from "classnames";
import styles from "./ArticleMainImage.module.sass";

const ArticleMainImage = ({ className, image, image2x }) => {
  return (
    <div className={cn(styles.player, className)}>
      <div className={styles.preview}>
        <img src={image} alt="preview" />
      </div>
    </div>
  );
};

export default ArticleMainImage;
