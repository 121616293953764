import React from "react";
import cn from "classnames";
import styles from "./Features.module.sass";
import Image from "../../../components/Image";

const items = [
  {
    title: "Clinics",
    content:
      "Our platform streamlines clinic operations, enhancing patient engagement and care coordination. Advanced tools for scheduling, record-keeping, and communication ensure efficient clinic management.",
    src: "/content/home-features-1.png",
    srcSet: "/content/home-features-1@2x.png",
    srcDark: "/content/home-features-dark-1.png",
    srcSetDark: "/content/home-features-dark-1@2x.png",
  },
  {
    title: "Medics",
    content:
      "Medics benefit from our integrated system, providing quick access to patient histories, appointment scheduling, and telemedicine capabilities, ensuring high-quality patient care.",
    src: "/content/home-features-2.png",
    srcSet: "/content/home-features-2@2x.png",
    srcDark: "/content/home-features-dark-2.png",
    srcSetDark: "/content/home-features-dark-2@2x.png",
  },
  {
    title: "Clients",
    content:
      "Clients experience seamless healthcare journeys with our user-friendly platform. Book appointments, access medical records, and communicate with healthcare providers effortlessly.",
    src: "/content/home-features-3.png",
    srcSet: "/content/home-features-3@2x.png",
    srcDark: "/content/home-features-dark-3.png",
    srcSetDark: "/content/home-features-dark-3@2x.png",
  },
];

const Features = () => {
  return (
    <div className={cn("section-bg section-mb0 section-pd", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>
            Integrated Medical Solutions
          </h2>
          <div className={cn("info", styles.info)}>
            Empowering Connections, Enhancing Care
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.line}>
            <svg
              width="839"
              height="137"
              viewBox="0 0 839 137"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M838 4.83887C823.237 25.0088 713.617 137.14 601.48 135.889C497.352 134.728 454.07 -36.7167 262.536 60.1723C94.2489 145.302 53.9174 52.5844 1.07634 1.99272"
                stroke="#E6E8EC"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="4 12"
              />
            </svg>
          </div>
          <div className={styles.list}>
            {items.map((x, index) => (
              <div className={styles.item} key={index}>
                <div className={styles.preview}>
                  <Image
                    srcSet={`${x.srcSet} 2x`}
                    srcSetDark={`${x.srcSetDark} 2x`}
                    src={x.src}
                    srcDark={x.srcDark}
                    alt="features"
                  />
                </div>
                <div className={styles.subtitle}>{x.title}</div>
                <div className={styles.text}>{x.content}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
