import React, { useState } from "react";
import cn from "classnames";
import styles from "./Careers.module.sass";
import Dropdown from "../../components/Dropdown";
import Icon from "../../components/Icon";
import TextInput from "../../components/TextInput";
import TextArea from "../../components/TextArea";
import Loader from "../../components/Loader";
import Preview from "./Preview";
import Modal from "../../components/Modal";
import CareersInfo from "../../components/CareersInfo";
import Success from "./Success";
import axios from "axios";

const positionOptions = [
  "Analytics and Data Science",
  "Corporate Functions",
  "Design",
  "Hardware",
  "Machine Learning and AI",
  "Marketing",
  "Operations",
  "Other",
  "Sales and Business Development",
  "Software",
  "Students",
  "Support and Service",
];

const locationOptions = [
  "Hybrid",
  "In Person",
  "Remote",
  "In Person or Remote",
];

const Careers = () => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [position, setPosition] = useState(positionOptions[0]);
  const [location, setLocation] = useState(locationOptions[0]);
  const [additionalInformation, setAdditionalInformation] = useState("");

  const [visibleSuccessfully, setVisibleSuccessfully] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [displayMessage, setDisplayMessage] = useState("");
  const [applicationId, setApplicationId] = useState("");

  const [visiblePreview, setVisiblePreview] = useState(false);
  const [visibleTransfer, setVisibleTransfer] = useState(false);
  const [fileName, setFileName] = useState("");

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setIsUploading(true);
      setFileName("");

      setTimeout(() => {
        setIsUploading(false);
        setFileName(file.name);
      }, 2000);
    } else {
      alert("Please upload a PDF file.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const payload = {
      fullName: fullName,
      email: email,
      phone: phone,
      position: position,
      location: location,
      additionalInformation: additionalInformation,
      type: "career",
      source: "gulahealth-website-careers",
    };

    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const base64 = await getBase64(file);
      payload.fileName = file.name;
      payload.fileContent = base64;
    }

    try {
      const response = await axios.post(
        "https://ayz16h0y0k.execute-api.us-east-2.amazonaws.com/dev/gulaCareer",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const responseBody = response.data;
      const parsedBody = JSON.parse(responseBody.body);

      console.log(parsedBody.message);

      if (parsedBody.message && parsedBody.message.includes("success")) {
        setIsSuccess(true);
        setVisibleSuccessfully(true);
        setApplicationId(parsedBody.applicationId);
        setFullName("");
        setEmail("");
        setPhone("");
        setPosition(positionOptions[0]);
        setLocation(locationOptions[0]);
        setAdditionalInformation("");
        setFileName("");
      } else {
        setIsSuccess(false);
        setVisibleSuccessfully(false);
        setDisplayMessage("Submission was not successful. Please try again.");
      }
    } catch (error) {
      console.error("Submission error:", error);
      setIsLoading(false);
      setDisplayMessage(
        "An error occurred during submission. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  return (
    <>
      <div className={cn("section", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.inner}>
            <div className={styles.wrapper}>
              <div className={styles.head}>
                <button
                  className={cn(
                    "button-stroke button-small tablet-show",
                    styles.button
                  )}
                  onClick={() => setVisibleTransfer(true)}
                  type="button"
                >
                  Why join us?
                </button>
                <div className={cn("h2", styles.title)}>Apply Now</div>
              </div>
              <form className={styles.form} action="" onSubmit={handleSubmit}>
                <div className={styles.list}>
                  <div className={styles.item}>
                    <div className={styles.category}>Personal Information</div>
                    <div className={styles.fieldset}>
                      <TextInput
                        className={styles.field}
                        label="Full Name"
                        name="name"
                        type="text"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        placeholder="e.g., John Doe"
                        required
                      />
                      <TextInput
                        className={styles.field}
                        label="Email Address"
                        name="email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="e.g., johndoe@example.com"
                        required
                      />
                      <TextInput
                        className={styles.field}
                        label="Phone Number"
                        name="phone"
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="e.g., +1 234 567 890"
                        required
                      />
                    </div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.category}>Upload CV/Resume</div>
                    <div className={styles.note}>
                      Drag or choose your file to upload
                    </div>
                    <div
                      className={cn(styles.file, {
                        [styles.loading]: isUploading,
                      })}
                    >
                      <input
                        className={styles.load}
                        type="file"
                        onChange={handleFileChange}
                        disabled={isUploading}
                      />
                      {!fileName && !isUploading && (
                        <>
                          <div className={styles.icon}>
                            <Icon name="upload-file" size="24" />
                          </div>
                          <div className={styles.format}>
                            PDF, DOC, DOCX. Max 10Mb.
                          </div>
                        </>
                      )}
                      {isUploading && (
                        <div className={styles.overlay}>
                          <Loader />
                        </div>
                      )}
                      {!isUploading && fileName && (
                        <div className={styles.fileName}>{fileName}</div>
                      )}
                    </div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.category}>Preferences</div>
                    <div className={styles.fieldset}>
                      <div className={styles.row}>
                        <div className={cn(styles.col, styles.w50)}>
                          <div className={styles.label}>Position</div>
                          <Dropdown
                            className={styles.dropdown}
                            label="Desired Position"
                            value={position}
                            setValue={setPosition}
                            options={positionOptions}
                          />
                        </div>
                        <div className={cn(styles.col, styles.w50)}>
                          <div className={styles.label}>Location</div>
                          <Dropdown
                            className={styles.dropdown}
                            label="Desired Position"
                            value={location}
                            setValue={setLocation}
                            options={locationOptions}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.category}>
                      Additional Information
                    </div>
                    <TextArea
                      className={styles.field}
                      label="Anyhting else you would like to add?"
                      name="additionalInformation"
                      value={additionalInformation}
                      onChange={(e) => setAdditionalInformation(e.target.value)}
                      placeholder="e. g. why you would like to work with us"
                      required
                    />
                  </div>
                </div>
                <div className={styles.foot}>
                  <button className={cn("button", styles.button)}>
                    <span>Submit Application</span>
                    {isLoading ? (
                      <Loader className={styles.loader} color="white" />
                    ) : (
                      <Icon name="arrow-next" size="10" />
                    )}
                  </button>
                  {displayMessage && (
                    <div className={styles.errorMessage}>
                      <strong>{displayMessage}</strong>
                    </div>
                  )}
                </div>
              </form>
            </div>
            <Preview
              className={cn(styles.preview, {
                [styles.active]: visiblePreview,
              })}
              onClose={() => setVisiblePreview(false)}
            />
          </div>
        </div>
      </div>
      <Modal
        visible={visibleSuccessfully}
        onClose={() => setVisibleSuccessfully(false)}
      >
        {isSuccess ? (
          <Success applicationId={applicationId} />
        ) : (
          <div className={styles.errorMessage}>
            <strong>{displayMessage}</strong>
          </div>
        )}
      </Modal>
      <Modal
        visible={visibleTransfer}
        onClose={() => setVisibleTransfer(false)}
      >
        <CareersInfo />
      </Modal>
    </>
  );
};

export default Careers;
