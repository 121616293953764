import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Item.module.sass";

const Item = ({ className, item }) => {
  const handleCardClick = () => {
    localStorage.setItem("selectedArticle", JSON.stringify(item));
  };

  return (
    <Link
      className={cn(className, styles.item)}
      to={`/newsroom-details/${item.title.toLowerCase().replace(/\s+/g, "-")}`}
      onClick={handleCardClick}
    >
      <div className={styles.preview}>
        <img
          srcSet={`${item.cardImage2x} 2x`}
          src={item.cardImage}
          alt="Nature"
        />
      </div>
      <div className={styles.title}>{item.title}</div>
      <div className={styles.counter}>{item.date}</div>
    </Link>
  );
};

export default Item;
