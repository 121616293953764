// mainImage: 2240 × 1310 pixels
// cardImage: 352 × 297 pixels
// cardImage2x at 2x: 704 × 594 pixels

// mainImage: 2240 × 1310 pixels
// cardImage: 500 × 500 pixels
// cardImage2x at 2x: 1000 × 1000 pixels

export const articles = [
  {
    title: "The Heart of the Matter: Understanding Heart Rate in Exercise",
    subtitle: "A Deep Dive into the Pulse of Cardiovascular Fitness",
    date: "2024-05-01",
    categoryText: "Wellness",
    new: true,
    featured: true,
    mainImage: "/content/articles/article-1/main.png",
    cardImage: "/content/articles/article-1/cardImage.png",
    cardImage2x: "/content/articles/article-1/cardImage@2x.png",
    sections: [
      {
        title: "The Basics of Heart Rate",
        subtitle: "Understanding Your Pulse",
        descriptions: [
          "Heart rate, often referred to as pulse, is the number of times your heart beats per minute and is a critical measure of heart health and overall physical fitness. It serves as a window to your cardiovascular system, reflecting the heart's ability to pump blood efficiently throughout your body.",
          "Resting heart rate is measured while at rest and is a vital indicator of cardiovascular health. A lower resting heart rate is generally associated with higher cardiovascular efficiency and better physical fitness. Factors such as stress, temperature, and body position can influence your resting heart rate.",
          "Knowing your resting heart rate can help you track your fitness level over time and identify potential health issues before they become serious.",
        ],
        // image: "/content/health/basics-heart-rate.jpg",
      },
      {
        title: "Heart Rate During Exercise",
        subtitle: "Finding Your Beat",
        descriptions: [
          "When you exercise, your heart rate increases to meet the higher demand for oxygen and nutrients by your muscles. Monitoring your heart rate during exercise can offer valuable insights into your cardiovascular and overall health, allowing you to adjust your workout intensity for optimal results.",
          "Understanding your target heart rate zones is crucial for maximizing the effectiveness of your workout while ensuring safety. These zones are determined as percentages of your maximum heart rate and are tailored to different training objectives, from fat burning to cardiovascular and anaerobic endurance.",
          "By exercising within these heart rate zones, you can ensure that you are not overexerting yourself while still reaping the maximum health benefits from your workout routine.",
        ],
      },
      {
        title: "Maximizing Cardiovascular Health",
        subtitle: "Strategies for Optimal Heart Fitness",
        descriptions: [
          "To achieve and maintain peak cardiovascular health, it's essential to engage in a balanced mix of aerobic and anaerobic exercises. Aerobic exercises, like brisk walking and cycling, help improve the efficiency of the heart and lungs, while anaerobic exercises, such as weightlifting and sprinting, strengthen the heart muscle and improve its pumping efficiency.",
          "Regular cardiovascular exercise can lead to a myriad of health benefits, including reduced risk of heart disease, lowered blood pressure, improved heart and lung function, and enhanced mental well-being. It's not just about the quantity of exercise but also the quality and variety that contribute to a healthier heart.",
          "Setting realistic goals, monitoring your progress through heart rate, and adjusting your exercise plan based on your body's response are key to a successful cardiovascular fitness regimen. Remember, consistency is more critical than intensity; regular moderate exercise is often more beneficial than sporadic high-intensity workouts.",
        ],
      },
      {
        title: "Technological Advancements in Heart Rate Monitoring",
        subtitle: "The Future of Fitness Tracking",
        descriptions: [
          "With the advent of wearable technology, monitoring your heart rate has never been easier. Devices like smartwatches and fitness bands allow you to track your heart rate in real-time, providing immediate feedback on your physical exertion levels and overall cardiovascular health.",
          "These devices not only track your heart rate during exercise but also monitor your resting heart rate over time, offering a comprehensive view of your heart health. Advanced features, such as heart rate variability (HRV) analysis, further delve into your body's stress levels and recovery status, enabling a more personalized and effective fitness regimen.",
          "As technology continues to evolve, the integration of AI and machine learning in heart rate monitoring devices promises even more personalized fitness insights, tailoring exercise recommendations to your unique physiological profile for optimal health outcomes.",
        ],
      },
    ],
    authors: [
      {
        name: "Gula Health Team",
      },
    ],
    acknowledgements: "Special thanks to the American Heart Association.",
  },
  {
    title: "Navigating the Waves of Blood Glucose: A Guide to Balanced Living",
    subtitle: "Mastering Your Sugar Levels for Optimal Health",
    date: "2024-05-01",
    categoryText: "Wellness",
    new: true,
    featured: true,
    mainImage: "/content/articles/article-2/main.png",
    cardImage: "/content/articles/article-2/cardImage.png",
    cardImage2x: "/content/articles/article-2/cardImage@2x.png",
    sections: [
      {
        title: "Understanding Blood Glucose",
        subtitle: "The Foundation of Energy",
        descriptions: [
          "Blood glucose, commonly known as blood sugar, is the primary source of energy for the body's cells. Maintained within a narrow range, these levels are vital for optimal body function.",
          "Glucose levels fluctuate throughout the day, influenced by factors such as diet, physical activity, and overall health. Understanding these dynamics is crucial for maintaining energy levels and preventing long-term health issues.",
        ],
      },
      {
        title: "The Impact of Diet on Glucose Levels",
        subtitle: "Eating for Balance",
        descriptions: [
          "The foods you consume play a significant role in managing your blood glucose levels. Complex carbohydrates, fiber-rich foods, and balanced meals contribute to a steady glucose supply.",
          "Incorporating a diverse range of nutrients in your diet can help stabilize blood sugar levels, reducing spikes and crashes that can affect your energy and mood.",
        ],
      },
      {
        title: "Exercise and Blood Glucose",
        subtitle: "A Dynamic Relationship",
        descriptions: [
          "Regular physical activity can significantly improve insulin sensitivity, allowing your muscles to use glucose more efficiently for energy.",
          "Exercise not only helps to lower blood glucose levels post-activity but also contributes to better glucose regulation in the long term. Finding the right balance and intensity of exercise is key to harnessing these benefits.",
        ],
      },
      {
        title: "Monitoring and Managing Blood Glucose",
        subtitle: "Tools and Techniques",
        descriptions: [
          "Regular monitoring of blood glucose levels is essential, especially for individuals with diabetes or those at risk. This practice helps in making informed decisions about diet, exercise, and medication.",
          "Advancements in technology have made monitoring simpler and more accurate, with devices ranging from traditional finger-prick tests to continuous glucose monitors (CGMs) that provide real-time data.",
        ],
      },
      {
        title: "The Psychological Aspect of Glucose Control",
        subtitle: "Mind, Mood, and Sugar",
        descriptions: [
          "Blood glucose levels can have a profound impact on mental health, affecting mood, cognitive function, and emotional well-being.",
          "Stress management, mindfulness, and adequate sleep are integral to maintaining balanced glucose levels and overall mental health.",
        ],
      },
    ],
    authors: [
      {
        name: "Gula Health Team",
      },
    ],
    acknowledgements: "Special thanks to the Global Diabetes Research Center.",
  },
  {
    title: "Elevating Your Heart: A Journey Through Cardio Fitness",
    subtitle: "Unlocking the Power of Cardiovascular Health",
    date: "2024-05-01",
    categoryText: "Wellness",
    new: true,
    featured: true,
    mainImage: "/content/articles/article-3/main.png",
    cardImage: "/content/articles/article-3/cardImage.png",
    cardImage2x: "/content/articles/article-3/cardImage@2x.png",
    sections: [
      {
        title: "The Heart of Cardio Fitness",
        subtitle: "Understanding the Core",
        descriptions: [
          "Cardio fitness, at its heart, involves exercises that increase the heart rate and promote better oxygen circulation throughout the body. It's a cornerstone of overall fitness, impacting everything from endurance to heart health.",
          "Regular cardio activity strengthens the heart muscle, improves lung capacity, and can lead to significant improvements in health and longevity. Discovering the right type and intensity of cardio is key to reaping its benefits.",
        ],
      },
      {
        title: "Types of Cardio Exercise",
        subtitle: "Finding Your Rhythm",
        descriptions: [
          "From brisk walking and running to swimming and cycling, the realm of cardio exercises is vast and varied. Each type offers unique benefits and can be tailored to fit personal preferences, fitness levels, and goals.",
          "Incorporating a mix of low-impact and high-intensity cardio exercises can optimize health benefits, prevent boredom, and reduce the risk of injury.",
        ],
      },
      {
        title: "The Role of Cardio in Weight Management",
        subtitle: "Balancing the Scale",
        descriptions: [
          "Cardio exercise is a powerful tool in weight management and body composition. By burning calories and improving metabolism, it can help maintain a healthy weight or contribute to weight loss when combined with a balanced diet.",
          "The key to success lies in consistency and combining cardio with strength training to maximize fat loss while preserving muscle mass.",
        ],
      },
      {
        title: "Cardio for Mental Health",
        subtitle: "Boosting Your Brain",
        descriptions: [
          "The benefits of cardio extend beyond the physical, significantly impacting mental health. Regular cardio activity can reduce stress, alleviate symptoms of depression and anxiety, and boost overall mood.",
          "The release of endorphins during cardio workouts, often referred to as the 'runner's high,' contributes to a sense of well-being and relaxation.",
        ],
      },
      {
        title: "Integrating Cardio into Your Lifestyle",
        subtitle: "Making Movement a Habit",
        descriptions: [
          "Incorporating cardio into your daily routine doesn't have to be daunting. Simple changes, like taking the stairs, walking or biking to work, or engaging in short, high-intensity workouts, can significantly contribute to your cardio fitness.",
          "Setting realistic goals, tracking progress, and varying your workouts can keep you motivated and committed to your cardio journey.",
        ],
      },
    ],
    authors: [
      {
        name: "Gula Health Team",
      },
    ],
    acknowledgements:
      "Special thanks to the International Cardiovascular Health Association.",
  },
  {
    title: "Tuning Into Hearing Health: Strategies for Lifelong Ear Care",
    subtitle: "A Comprehensive Guide to Auditory Wellness",
    date: "2024-05-01",
    categoryText: "Wellness",
    new: true,
    featured: true,
    mainImage: "/content/articles/article-4/main.png",
    cardImage: "/content/articles/article-4/cardImage.png",
    cardImage2x: "/content/articles/article-4/cardImage@2x.png",
    sections: [
      {
        title: "The Basics of Hearing Health",
        subtitle: "Understanding Your Ears",
        descriptions: [
          "Hearing health is a crucial aspect of overall wellness, involving the care and maintenance of the ear's complex structures that allow us to detect and interpret sounds. Regular hearing check-ups and protective measures can prevent long-term damage.",
          "Understanding the anatomy of the ear and how sound is processed is the first step toward maintaining good hearing health. Factors such as noise exposure, age, and genetics play significant roles in hearing abilities.",
        ],
      },
      {
        title: "Protecting Your Ears from Noise",
        subtitle: "The Shield Against Sound",
        descriptions: [
          "Exposure to loud noise is one of the most common causes of hearing loss. Employing protective measures, such as using earplugs or earmuffs in noisy environments, can significantly reduce the risk of noise-induced hearing damage.",
          "Understanding safe noise levels and the importance of regular quiet periods can help preserve hearing function and prevent the onset of noise-induced hearing loss.",
        ],
      },
      {
        title: "The Impact of Lifestyle on Hearing",
        subtitle: "Everyday Choices, Lasting Effects",
        descriptions: [
          "Lifestyle factors, including diet, exercise, and smoking, can have a profound impact on hearing health. Nutrients from a balanced diet can support ear function, while regular exercise improves blood flow, benefiting the ears.",
          "Avoiding ototoxic chemicals in medications and environmental pollutants, along with cessation of smoking, can also contribute to healthier hearing.",
        ],
      },
      {
        title: "Technological Advancements in Hearing Care",
        subtitle: "Innovations for Better Ears",
        descriptions: [
          "Technological advancements in audiology have revolutionized the diagnosis, treatment, and management of hearing loss. From sophisticated hearing aids to cochlear implants, options for enhancing hearing are more effective and accessible than ever.",
          "Emerging technologies such as AI-driven hearing devices and apps for hearing health monitoring are paving the way for personalized and proactive ear care.",
        ],
      },
      {
        title: "The Connection Between Hearing and Mental Health",
        subtitle: "Listening to Your Mind",
        descriptions: [
          "Hearing loss can have significant psychological effects, including social isolation, depression, and cognitive decline. Acknowledging and addressing the mental health aspects of hearing impairment is essential for comprehensive care.",
          "Strategies for coping with hearing loss, such as joining support groups and utilizing assistive listening devices, can alleviate its impact on mental well-being.",
        ],
      },
    ],
    authors: [
      {
        name: "Gula Health Team",
      },
    ],
    acknowledgements:
      "Special thanks to the International Hearing Health Foundation.",
  },
  {
    title: "The Silent Healer: Unraveling the Mysteries of Sleep",
    subtitle: "Journey into the World of Restorative Slumber",
    date: "2024-05-01",
    categoryText: "Wellness",
    new: true,
    featured: true,
    mainImage: "/content/articles/article-5/main.png",
    cardImage: "/content/articles/article-5/cardImage.png",
    cardImage2x: "/content/articles/article-5/cardImage@2x.png",
    sections: [
      {
        title: "The Essence of Sleep",
        subtitle: "More Than Just Rest",
        descriptions: [
          "Sleep, a complex and dynamic process, is fundamental to human life, playing a critical role in physical health, brain function, and emotional well-being.",
          "The sleep cycle, composed of various stages including REM and non-REM sleep, facilitates crucial processes such as memory consolidation, muscle repair, and the release of hormones regulating growth and appetite.",
        ],
      },
      {
        title: "The Health Benefits of Quality Sleep",
        subtitle: "A Cornerstone of Wellness",
        descriptions: [
          "Adequate sleep is essential for maintaining optimal health, supporting immune function, reducing the risk for chronic illnesses, aiding in weight management, and enhancing mental health and mood.",
          "Consistently good sleep can improve concentration, productivity, and cognitive performance, highlighting its importance beyond mere physical health.",
        ],
      },
      {
        title: "Common Sleep Challenges",
        subtitle: "Obstacles to Restful Nights",
        descriptions: [
          "Many individuals face sleep challenges, including difficulty falling asleep, staying asleep, or experiencing restorative sleep, often due to stress, lifestyle choices, or health conditions.",
          "Understanding and addressing these challenges through lifestyle modifications, stress management, and medical consultation can significantly improve sleep quality.",
        ],
      },
      {
        title: "Improving Sleep Hygiene",
        subtitle: "Strategies for Better Sleep",
        descriptions: [
          "Good sleep hygiene involves creating a conducive sleep environment and adopting behaviors that promote consistent, uninterrupted sleep. Key strategies include adhering to a regular sleep schedule, optimizing the bedroom environment for comfort and relaxation, and managing pre-sleep activities.",
          "Mindfulness and relaxation techniques, along with limiting exposure to light and electronics before bedtime, can also enhance sleep quality.",
        ],
      },
      {
        title: "The Future of Sleep Science",
        subtitle: "Emerging Trends and Innovations",
        descriptions: [
          "Ongoing research in sleep science continues to uncover the intricate mechanisms of sleep and its profound impact on health, leading to innovative approaches and technologies for enhancing sleep quality.",
          "Advances in wearable technology, personalized medicine, and cognitive-behavioral therapies hold promise for more effective management of sleep disorders and optimization of sleep for overall well-being.",
        ],
      },
    ],
    authors: [
      {
        name: "Gula Health Team",
      },
    ],
    acknowledgements: "Special thanks to the National Sleep Foundation.",
  },
  {
    title: "Hydration: The Elixir of Life",
    subtitle: "Unveiling the Power of Water in Health and Wellness",
    date: "2024-05-01",
    categoryText: "Wellness",
    new: true,
    featured: true,
    mainImage: "/content/articles/article-6/main.png",
    cardImage: "/content/articles/article-6/cardImage.png",
    cardImage2x: "/content/articles/article-6/cardImage@2x.png",
    sections: [
      {
        title: "The Essence of Hydration",
        subtitle: "Water as the Source of Vitality",
        descriptions: [
          "Hydration is fundamental to life, with water making up about 60% of the human body. It's essential for maintaining the balance of bodily fluids, which are involved in digestion, absorption, circulation, creation of saliva, transportation of nutrients, and maintenance of body temperature.",
          "Understanding the importance of water in regulating body functions and supporting health is the first step towards embracing hydration as a key component of wellness.",
        ],
      },
      {
        title: "Benefits of Staying Hydrated",
        subtitle: "Beyond Quenching Thirst",
        descriptions: [
          "Proper hydration is linked to numerous health benefits, including enhanced brain function, improved physical performance, better digestion, and detoxification. It also plays a crucial role in maintaining skin health and regulating body temperature.",
          "The long-term benefits of adequate water intake include prevention of urinary tract infections, kidney stones, and hypertension, highlighting the critical role of hydration in preventing chronic diseases.",
        ],
      },
      {
        title: "Identifying and Overcoming Dehydration",
        subtitle: "Recognizing the Signs",
        descriptions: [
          "Dehydration occurs when you use or lose more fluid than you take in, and your body doesn't have enough water to carry out its normal functions. Recognizing the early signs of dehydration, which can include thirst, dry mouth, fatigue, and dark-colored urine, is essential for taking timely action to rehydrate.",
          "Preventing dehydration is key, especially during hot weather, intense exercise, or illness. Consuming sufficient fluids, eating water-rich foods, and being mindful of fluid loss are proactive steps to maintain hydration.",
        ],
      },
      {
        title: "Hydration Strategies for Optimal Health",
        subtitle: "Effective Ways to Stay Hydrated",
        descriptions: [
          "Staying hydrated is not just about drinking water; it involves understanding and implementing effective hydration strategies. These can include carrying a water bottle, consuming fruits and vegetables with high water content, and drinking water before, during, and after exercise.",
          "Hydration needs can vary based on factors such as age, gender, weight, climate, and physical activity levels. Tailoring your hydration strategy to your personal needs and lifestyle can optimize your health and well-being.",
        ],
      },
    ],
    authors: [
      {
        name: "Gula Health Team",
      },
    ],
  },
];
