import React, { useState } from "react";
import cn from "classnames";
import styles from "./Footer.module.sass";
import { Link } from "react-router-dom";
import Image from "../Image";
import Form from "../Form";
import axios from "axios";

const items = [
  {
    title: "About",
    url: "/about",
  },
  {
    title: "Activity",
    url: "/activity",
  },
  {
    title: "Careers",
    url: "/careers",
  },
  {
    title: "Newsroom",
    url: "/newsroom",
  },
  {
    title: "Privacy Policy",
    url: "/privacy-policy",
  },
  {
    title: "Terms & Legal",
    url: "/terms-legal",
  },
];

const Footer = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [displayMessage, setDisplayMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setDisplayMessage("");
    try {
      const requestBody = {
        email: email,
        type: "announcement",
        source: "gulahealth-website-footer",
      };
      const response = await axios.post(
        "https://0gbi0gwcj1.execute-api.us-east-2.amazonaws.com/dev/gulaLead",
        requestBody
      );
      const responseBody = JSON.parse(response.data.body);
      console.log(responseBody);
      if (responseBody.message.includes("Duplicate email")) {
        setIsSuccess(false);
        setDisplayMessage(responseBody.message);
      } else {
        setIsSuccess(true);
        setDisplayMessage(
          "You have successfully subscribed to our newsletter!"
        );
      }
    } catch (error) {
      console.error(error);
      setDisplayMessage("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles.footer}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <Link className={styles.logo} to="/">
              <Image
                className={styles.pic}
                src="/logo/logo-dark.svg"
                srcDark="/logo/logo-light.svg"
                alt="Gula"
              />
            </Link>
          </div>
          <div className={styles.col}>
            <div className={styles.menu}>
              {items.map((x, index) => (
                <Link className={styles.link} to={x.url} key={index}>
                  {x.title}
                </Link>
              ))}
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.info}>Join our newsletter</div>
            {isSuccess ? (
              <div className={styles.successMessage}>
                <strong>{displayMessage}</strong>
              </div>
            ) : (
              <>
                <Form
                  className={styles.form}
                  value={email}
                  setValue={setEmail}
                  onSubmit={handleSubmit}
                  placeholder="Enter your email"
                  type="email"
                  name="email"
                  icon="arrow-next"
                  loading={isLoading}
                />
                {displayMessage && (
                  <div className={styles.errorMessage}>
                    <strong>{displayMessage}</strong>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.copyright}>
            Copyright © 2024 Gula Health Inc. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
