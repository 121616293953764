import React, { useState } from "react";
import cn from "classnames";
import styles from "./Question.module.sass";
import Icon from "../../../components/Icon";
import Dropdown from "../../../components/Dropdown";
import TextInput from "../../../components/TextInput";
import TextArea from "../../../components/TextArea";
import axios from "axios";
import Loader from "../../../components/Loader";
import Modal from "../../../components/Modal";
import Success from "./Success";

const optionsInquiry = [
  "General question",
  "Inquiry about appointment scheduling",
  "Question about prescription refills",
  "Question about test results",
  "Question about medical records",
  "Question about billing",
  "Question about insurance",
  "Assistance with online patient portal",
  "Feedback on telehealth services",
  "Billing and insurance queries",
  "Other",
];

const Question = ({ scrollToRef }) => {
  const [topic, setTopic] = useState("General");
  const [inquiry, setInquiry] = useState(optionsInquiry[0]);
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [visibleSuccessfully, setVisibleSuccessfully] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [displayMessage, setDisplayMessage] = useState("");
  const [ticketId, setTicketId] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const requestBody = {
        topic: topic,
        inquiry: inquiry,
        email: email,
        subject: subject,
        message: message,
        type: "contact",
        source: "gulahealth-website-support",
      };
      console.log(requestBody);
      const response = await axios.post(
        "https://sk18wxmt98.execute-api.us-east-2.amazonaws.com/dev/gulaSupport",
        requestBody
      );
      const responseBody = JSON.parse(response.data.body);
      console.log(responseBody);
      if (responseBody.message.includes("success")) {
        setIsSuccess(true);
        setVisibleSuccessfully(true);
        setTicketId(responseBody.ticketId);
        setEmail("");
        setSubject("");
        setMessage("");
        setTopic("General");
        setInquiry(optionsInquiry[0]);
      } else {
        setIsSuccess(false);
        setVisibleSuccessfully(true);
      }
    } catch (error) {
      console.error(error);
      setDisplayMessage("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className={cn("section-pd section-bg section-mb0", styles.section)}
        ref={scrollToRef}
      >
        <div className={cn("container", styles.container)}>
          <form className={styles.form} action="" onSubmit={handleSubmit}>
            <div className={styles.fieldset}>
              <div className={styles.field}>
                <div className={styles.label}>What is your inquiry about?</div>
                <div className={styles.variants}>
                  <label className={styles.radio}>
                    <input
                      className={styles.input}
                      type="radio"
                      name="topic"
                      value="General"
                      onChange={(e) => setTopic(e.target.value)}
                      checked={topic === "General"}
                    />
                    <span className={styles.inner}>
                      <div
                        className={styles.icon}
                        style={{ backgroundColor: "#3772FF" }}
                      >
                        <Icon name="lightning" size="20" />
                      </div>
                      <span className={styles.text}>General</span>
                    </span>
                  </label>
                  <label className={styles.radio}>
                    <input
                      className={styles.input}
                      type="radio"
                      name="topic"
                      value="Clinics"
                      onChange={(e) => setTopic(e.target.value)}
                      checked={topic === "Clinics"}
                    />
                    <span className={styles.inner}>
                      <div
                        className={styles.icon}
                        style={{ backgroundColor: "#58C27D" }}
                      >
                        <Icon name="clinic" size="50" />
                      </div>
                      <span className={styles.text}>Clinics</span>
                    </span>
                  </label>
                </div>
                <div className={styles.variants}>
                  <label className={styles.radio}>
                    <input
                      className={styles.input}
                      type="radio"
                      name="topic"
                      value="Medics"
                      onChange={(e) => setTopic(e.target.value)}
                      checked={topic === "Medics"}
                    />
                    <span className={styles.inner}>
                      <div
                        className={styles.icon}
                        style={{ backgroundColor: "#92A5EF" }}
                      >
                        <Icon name="medic" size="20" />
                      </div>
                      <span className={styles.text}>Medics</span>
                    </span>
                  </label>
                  <label className={styles.radio}>
                    <input
                      className={styles.input}
                      type="radio"
                      name="topic"
                      value="Clients"
                      onChange={(e) => setTopic(e.target.value)}
                      checked={topic === "Clients"}
                    />
                    <span className={styles.inner}>
                      <div
                        className={styles.icon}
                        style={{ backgroundColor: "#8BC5E5" }}
                      >
                        <Icon name="client" size="20" />
                      </div>
                      <span className={styles.text}>Clients</span>
                    </span>
                  </label>
                </div>
              </div>
              <div className={styles.field}>
                <Dropdown
                  className={styles.dropdown}
                  label="What is your inquiry about?"
                  value={inquiry}
                  setValue={setInquiry}
                  options={optionsInquiry}
                />
              </div>
              <TextInput
                className={styles.field}
                label="Email address"
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email address"
                required
              />

              <TextInput
                className={styles.field}
                label="Subject"
                name="subject"
                type="text"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                placeholder="Enter your subject"
                required
              />

              <TextArea
                className={styles.field}
                label="Message"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="How can we help you?"
                required
              />
            </div>
            <div className={styles.btns}>
              <button
                className={cn("button-small", styles.button)}
                disabled={isLoading}
              >
                {isLoading && (
                  <Loader className={styles.loader} color="white" />
                )}
                Send message
              </button>
            </div>
            {displayMessage && (
              <div className={styles.errorMessage}>
                <strong>{displayMessage}</strong>
              </div>
            )}
          </form>
        </div>
      </div>
      <Modal
        visible={visibleSuccessfully}
        onClose={() => setVisibleSuccessfully(false)}
      >
        {isSuccess ? (
          <Success ticketId={ticketId} />
        ) : (
          <div className={styles.errorMessage}>
            <strong>{displayMessage}</strong>
          </div>
        )}
      </Modal>
    </>
  );
};

export default Question;
