import React from "react";
import styles from "./CareersInfo.module.sass";
import CareersCard from "../CareersCard";

const compensationDetails = [
  {
    title: "Competitive Salary",
    subtitle: "Including performance bonuses and increments",
    options: [
      { title: "Annual Performance Bonuses", icon: "dollar-sign" },
      { title: "Regular Salary Reviews", icon: "chart-line" },
    ],
  },
  {
    title: "Health Benefits",
    subtitle: "Comprehensive medical, dental, and vision plans",
    options: [
      { title: "Full Medical Coverage", icon: "stethoscope" },
      { title: "Dental and Vision Plans", icon: "tooth" },
    ],
  },
  {
    title: "Retirement Plans",
    subtitle: "Supporting your long-term financial well-being",
  },
  {
    title: "Professional Development",
    subtitle: "Investing in your growth and career advancement",
  },
  {
    title: "Work-Life Balance",
    subtitle: "Flexible work arrangements and paid time off",
  },
];

const whatWeLookFor = [
  {
    title: "A Passion for Healthcare",
    subtitle: "Seeking individuals committed to making a difference",
    options: [
      { title: "Empathy and Compassion", icon: "heart" },
      { title: "Innovative Thinking", icon: "lightbulb" },
    ],
  },
  {
    title: "Team Collaboration",
    subtitle: "Valuing teamwork and effective communication",
    options: [
      { title: "Strong Team Players", icon: "users" },
      { title: "Excellent Communication Skills", icon: "comment-dots" },
    ],
  },
  {
    title: "Leadership Qualities",
    subtitle: "Inspiring others and leading by example",
  },
  {
    title: "Problem-Solving Skills",
    subtitle: "Analytical thinking and creative solutions",
  },
  {
    title: "Adaptability",
    subtitle: "Flexibility in a rapidly changing environment",
  },
];

const CareersInfo = () => {
  return (
    <div className={styles.transfer}>
      <div className={styles.title}>Compensation & Benefits</div>
      {compensationDetails.map((x, index) => (
        <CareersCard className={styles.card} item={x} key={index} />
      ))}

      <div className={styles.title}>What we look for</div>
      {whatWeLookFor.map((x, index) => (
        <CareersCard className={styles.card} item={x} key={index} />
      ))}
    </div>
  );
};

export default CareersInfo;
