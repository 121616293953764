import React from "react";
import cn from "classnames";
import { NavLink, useLocation } from "react-router-dom";
import styles from "./Panel.module.sass";
import Icon from "../Icon";

const Panel = ({
  className,
  menu,
  classBody,
  children,
  onSearch,
  classButtonSearch,
}) => {
  const { pathname } = useLocation();

  return (
    <div className={cn(className, styles.panel)}>
      <div className={styles.background}></div>

      <div className={cn(styles.body, classBody)}>{children}</div>
    </div>
  );
};

export default Panel;
