import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";

const Item = ({ className, item }) => {
  const [visible, setVisible] = useState(false);

  return (
    <div className={cn(className, styles.item, { [styles.active]: visible })}>
      <div className={styles.head} onClick={() => setVisible(!visible)}>
        {item.question}
      </div>
      {visible && (
        <div className={styles.body}>
          <div className={styles.content}>{item.answer}</div>
        </div>
      )}
    </div>
  );
};

export default Item;
