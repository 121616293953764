import React, { useState } from "react";
import cn from "classnames";
import styles from "./Info.module.sass";
import Icon from "../../../components/Icon";
import Modal from "../../../components/Modal";
import ActivityPrivacy from "../../../components/ActivityPrivacy";
import ActivityPlatforms from "../../../components/ActivityPlatforms";

const items = [
  {
    title: "Platforms",
    content:
      "Experience Gula on iOS and Android, tailored for clients, and a web interface for clinics.",
    color: "#f4f5f6",
    image: "/content/activity-platforms-1.png",
    image2x: "/content/activity-platforms-1@2x.png",
    buttonText: "Learn More",
  },
  {
    title: "Security",
    content:
      "Our platforms are HIPAA compliant and we are committed to protecting your data.",
    color: "#141416",
    image: "/content/activity-security-1.png",
    image2x: "/content/activity-security-1@2x.png",
    buttonText: "Our Commitment",
  },
];

const Info = ({ className }) => {
  const [visiblePlatformInfo, setVisiblePlatformInfo] = useState(false);
  const [visiblePrivacyInfo, setVisiblePrivacyInfo] = useState(false);

  return (
    <>
      <div className={cn(className, styles.actions)}>
        <div className={styles.list}>
          {items.map((x, index) => (
            <div className={styles.item} key={index}>
              <div
                className={styles.preview}
                style={{ backgroundColor: x.color }}
              >
                <img srcSet={`${x.image2x} 2x`} src={x.image} alt="Action" />
              </div>
              <div className={styles.details}>
                <div className={styles.title}>{x.title}</div>
                <div className={styles.content}>{x.content}</div>
                {x.title === "Platforms" ? (
                  <button
                    className={cn("button-stroke button-small", styles.button)}
                    onClick={() => setVisiblePlatformInfo(true)}
                  >
                    <span>{x.buttonText}</span>
                    <Icon name="arrow-next" size="10" />
                  </button>
                ) : x.title === "Security" ? (
                  <button
                    className={cn("button-stroke button-small", styles.button)}
                    onClick={() => setVisiblePrivacyInfo(true)}
                  >
                    <span>{x.buttonText}</span>
                    <Icon name="arrow-next" size="10" />
                  </button>
                ) : null}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Modal
        visible={visiblePlatformInfo}
        onClose={() => setVisiblePlatformInfo(false)}
      >
        <ActivityPlatforms />
      </Modal>
      <Modal
        visible={visiblePrivacyInfo}
        onClose={() => setVisiblePrivacyInfo(false)}
      >
        <ActivityPrivacy />
      </Modal>
    </>
  );
};

export default Info;
