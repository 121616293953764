import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";
import Panel from "../../../components/PanelTitle";

const Main = () => {
  return (
    <div className={cn("section-mb80", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.preview}>
          <img src="/content/about-us-main.png" alt="Main" />
          <div className={styles.wrap}>
            <h1 className={cn("hero", styles.title)}>About Us</h1>
          </div>
        </div>
        <Panel className={styles.panel} classBody={styles.body}>
          <div className={styles.head}>
            <div className={cn("info", styles.info)}>
              We are on a mission to build tools that will improve access and
              quality of healthcare for all of Humanity.{" "}
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default Main;
