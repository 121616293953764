import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Catalog.module.sass";
import Icon from "../../../components/Icon";
import Dropdown from "../../../components/Dropdown";
import NewsCard from "../../../components/NewsCard";

import { articles as initialArticles } from "../../../data/articles";

const dateOptions = ["Recently added", "Last week", "Last month", "This year"];
const sortingOptions = [
  "All",
  "Developers",
  "Platforms",
  "Research",
  "Tutorial",
  "Wellness",
];

const Catalog = ({ scrollToRefCatalog }) => {
  const [sorting, setSorting] = useState(sortingOptions[0]);
  const [searchQuery, setSearchQuery] = useState("");
  const [date, setDate] = useState(dateOptions[0]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [displayedArticles, setDisplayedArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const articlesPerPage = 9;

  useEffect(() => {
    const filterCategory = sortingOptions[activeIndex];
    const newFilteredArticles = initialArticles
      .filter(
        (article) =>
          filterCategory === "All" || article.categoryText === filterCategory
      )
      .filter((article) =>
        article.title.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort((a, b) => new Date(b.date) - new Date(a.date));

    setFilteredArticles(newFilteredArticles);
    setDisplayedArticles(newFilteredArticles.slice(0, articlesPerPage));
  }, [searchQuery, activeIndex, date]);

  const handleShowMore = () => {
    const totalArticlesShown = displayedArticles.length + articlesPerPage;
    setDisplayedArticles(filteredArticles.slice(0, totalArticlesShown));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <div
      className={cn("section-pd section-mb0", styles.section)}
      ref={scrollToRefCatalog}
    >
      <div className={cn("container", styles.container)}>
        <form className={styles.form} action="" onSubmit={handleSubmit}>
          <input
            className={styles.input}
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            name="search"
            placeholder="Search healthcare articles, topics, or keywords"
            required
          />
          <button className={cn("button-circle", styles.result)}>
            <Icon name="search" size="24" />
          </button>
        </form>
        <div className={styles.sorting}>
          <div className={styles.dropdown}>
            <Dropdown
              className={styles.dropdown}
              value={date}
              setValue={setDate}
              options={dateOptions}
            />
          </div>
          <div className={styles.nav}>
            {sortingOptions.map((x, index) => (
              <button
                className={cn(styles.link, {
                  [styles.active]: index === activeIndex,
                })}
                onClick={() => setActiveIndex(index)}
                key={index}
              >
                {x}
              </button>
            ))}
          </div>
          <div className={cn("tablet-show", styles.dropdown)}>
            <Dropdown
              className={styles.dropdown}
              value={sorting}
              setValue={setSorting}
              options={sortingOptions}
            />
          </div>
        </div>
        <div className={styles.list}>
          {filteredArticles.length > 0 ? (
            displayedArticles.map((x, index) => (
              <NewsCard className={styles.card} item={x} key={index} />
            ))
          ) : (
            <div className={styles.option}>
              <Icon name="cancel" size="16" />
              <p>No results found</p>
            </div>
          )}
        </div>

        {filteredArticles.length > displayedArticles.length &&
          filteredArticles.length > articlesPerPage && (
            <div className={styles.btns}>
              <button
                className={cn("button-stroke button-small", styles.button)}
                onClick={handleShowMore}
              >
                <span>View more</span>
                <Icon name="arrow-down" size="16" />
              </button>
            </div>
          )}
      </div>
    </div>
  );
};

export default Catalog;
