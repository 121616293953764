import React, { useRef } from "react";
import Main from "./Main";
import Featured from "./Featured";
import Catalog from "./Catalog";
import Contact from "./Contact";

const Newsroom = () => {
  const scrollToRefCatalog = useRef(null);
  const scrollToRefContact = useRef(null);

  return (
    <>
      <Main
        scrollToRefCatalog={scrollToRefCatalog}
        scrollToRefContact={scrollToRefContact}
      />
      <Featured />
      <Catalog scrollToRefCatalog={scrollToRefCatalog} />
      <Contact scrollToRefContact={scrollToRefContact} />
    </>
  );
};

export default Newsroom;
