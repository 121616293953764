import React from "react";
import cn from "classnames";
import styles from "./Brands.module.sass";
import { Link } from "react-router-dom";

const brands = [
  { src: "/content/brands/brand-bdhcanada.png", url: "/" },
  { src: "/content/brands/brand-marycare.png", url: "http://www.marycare.nl" },
  { src: "/content/brands/brand-laju.png", url: "http://www.laju.nl" },
  { src: "/content/brands/brand-nactalia.png", url: "http://www.nactalia.com" },
];

const Brands = () => {
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <h2 className={cn("h2", styles.title)}>Our Sponsors</h2>

        <div className={styles.wrap}>
          <div className={styles.list}>
            {brands.map((brand, index) => {
              const isExternalLink = brand.url.startsWith("http");
              const BrandLink = isExternalLink ? "a" : Link;
              const linkProps = isExternalLink
                ? {
                    href: brand.url,
                    target: "_blank",
                    rel: "noopener noreferrer",
                  }
                : { to: brand.url };

              return (
                <BrandLink {...linkProps} className={styles.item} key={index}>
                  <img src={brand.src} alt="Brand" />
                </BrandLink>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Brands;
