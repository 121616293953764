import React from "react";
import cn from "classnames";
import styles from "./Contact.module.sass";
import { Link } from "react-router-dom";

const Contact = ({ scrollToRefContact }) => {
  return (
    <div
      className={cn("section-bg section-pd section-mb0", styles.section)}
      ref={scrollToRefContact}
    >
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <div className={styles.stage}>Join us</div>
          <h1 className={cn("h2", styles.title)}>Careers at Gula Health</h1>
          <div className={styles.info}>
            Where innovation meets purpose. We're looking for creative minds
            eager to shape the future of healthcare. If you're ready to
            contribute to a healthier world, we welcome you to explore our
            career opportunities.
          </div>
          <div className={styles.btns}>
            <Link
              className={cn("button-stroke button-small", styles.button)}
              to="/careers"
            >
              Learn more
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
