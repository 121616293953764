import React, { useState } from "react";
import cn from "classnames";
import styles from "./Faq.module.sass";
import Dropdown from "../../../components/Dropdown";
import Icon from "../../../components/Icon";
import Item from "./Item";

const items = [
  {
    title: "General",
    icon: "lightning",
    items: [
      {
        question: "How can I access my medical records online?",
        answer:
          "You can access your medical records by logging into our patient app, available for iOS and Android.",
      },
      {
        question: "What telehealth services do you offer?",
        answer:
          "We offer a range of telehealth services, including online appointment booking and prescription refills.",
      },
      {
        question: "Can I schedule appointments online?",
        answer:
          "Yes, you can schedule appointments directly through our website or mobile app.",
      },
      {
        question: "How do I refill a prescription through your platform?",
        answer:
          "To refill a prescription, log into your account, navigate to the 'Prescriptions' tab, and select the medication you need to refill. Refills are subject to doctor's approval.",
      },
      {
        question: "What wellness resources do you provide?",
        answer:
          "We offer various wellness resources, including health tips, exercise guides, and nutritional advice. Check our newsroom for more information.",
      },
    ],
  },
  {
    title: "Clinic",
    icon: "clinic",
    items: [
      {
        question: "What types of specialists are available on your platform?",
        answer:
          "Our platform offers a range of specialists, including cardiologists, neurologists, chiropractors, and pediatricians.",
      },
      {
        question: "How do I set up my clinic's hours and locations?",
        answer:
          "You can set your clinic's hours and locations by logging into your account and navigating to the settings section.",
      },
      {
        question:
          "How do I transfer my clinic's medical records to your platform?",
        answer:
          "To transfer your clinic's medical records, contact our support team, who will guide you through the process.",
      },
      {
        question: "Are virtual onboarding sessions available for new clinics?",
        answer:
          "Yes, we offer virtual onboarding sessions for new clinics. Please contact our support team to schedule a session.",
      },
      {
        question:
          "How do I access my clinic's billing information, including invoices and subscriptions?",
        answer:
          "You can access your clinic's billing information by logging into your account and navigating to the 'Invoices' section. To view and manage subscriptions, go to 'Settings' and select 'Subscriptions'.",
      },
    ],
  },
  {
    title: "Medic",
    icon: "medic",
    items: [
      {
        question: "How do I access my account on the web platform?",
        answer:
          "You must be registered with a clinic on our platform to access your account on the web. Once registered, you can log in using your email and password.",
      },
      {
        question: "How do I set up notifications for appointment reminders?",
        answer:
          "Set up notifications for appointment reminders by logging into your account and navigating to 'Settings'. Under 'Notifications', you can select your preferences.",
      },
      {
        question: "How can I change my display name and profile picture?",
        answer:
          "To change your display name and profile picture, log into your account and navigate to the 'Account' section. Here, you can update your personal information.",
      },
      {
        question: "Can a medic register without a clinic on the platform?",
        answer:
          "Currently, you must be registered with a clinic on our platform to access your account. Please contact our support team for more information.",
      },
    ],
  },
  {
    title: "Client",
    icon: "client",
    items: [
      {
        question: "How do I view and pay my bills online?",
        answer:
          "You can view and pay your bills by logging into your account on our website or mobile app.",
      },
      {
        question: "Which insurance plans do you accept?",
        answer:
          "We accept a variety of insurance plans. Please check our website under the 'Care' tab or contact us directly for more information.",
      },
      {
        question: "Is your platform free for clients to use?",
        answer:
          "Yes, our platform is free for clients to use. However, some clinic services may require payment.",
      },
      {
        question: "What should I do if I have billing disputes or questions?",
        answer:
          "If you have any billing disputes or questions, you can contact the clinic directly or reach out to our billing department for assistance.",
      },
    ],
  },
];

const Faq = ({ scrollToRef }) => {
  const options = [];
  items.map((x) => options.push(x.title));
  const [direction, setDirection] = useState(options[0]);

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <div className={styles.stage}>We're here to help</div>
          <h1 className={cn("h2", styles.title)}>Frequently asked questions</h1>
          <div className={styles.info}>
            Find answers to common questions, or for more personalized
            assistance,{" "}
            <a
              rel="noopener noreferrer"
              onClick={() =>
                scrollToRef.current.scrollIntoView({ behavior: "smooth" })
              }
            >
              Contact Our Support Team
            </a>
          </div>
          <Dropdown
            className={cn("mobile-show", styles.dropdown)}
            value={direction}
            setValue={setDirection}
            options={options}
          />
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.nav}>
              {items.map((x, index) => (
                <div
                  className={cn(styles.link, {
                    [styles.active]: x.title === direction,
                  })}
                  onClick={() => setDirection(x.title)}
                  key={index}
                >
                  <Icon name={x.icon} size="16" />
                  <span>{x.title}</span>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.col}>
            {items
              .find((x) => x.title === direction)
              .items.map((item, index) => (
                <Item className={styles.item} item={item} key={index} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
