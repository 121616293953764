import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";
import ArticleMainImage from "../../../components/ArticleMainImage";
import { Link } from "react-router-dom";
import Icon from "../../../components/Icon";

const Main = ({ article }) => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.topBar}>
          <Link
            className={cn("button-stroke button-small", styles.button)}
            to="/newsroom"
          >
            {/* <Icon name="arrow-left" size="10" /> */}
            <span>Go To Newsroom</span>
          </Link>
          <div className={styles.info}>
            Last Updated <strong>{article.date}</strong>
          </div>
        </div>
        <div className={styles.head}>
          <h1 className={cn("h1", styles.title)}>{article.title}</h1>
          <div className={styles.subtitle}>{article.subtitle}</div>
        </div>
        <ArticleMainImage className={styles.player} image={article.mainImage} />
      </div>
    </div>
  );
};

export default Main;
