import React from "react";
import cn from "classnames";
import styles from "./Cards.module.sass";

const items = [
  {
    title: "Seamless API Access",
    subtitle: "Integrate Effortlessly, Enhance Patient Care",
    image: "content/developer-icon-api.svg",
  },
  {
    title: "Sandbox Testing Environment",
    subtitle: "Innovate Safely, Deploy Confidently",
    image: "content/developer-icon-sandbox.svg",
  },
  {
    title: "Real-time Data Insights",
    subtitle: "Empower Decisions, Elevate Health Outcomes",
    image: "content/developer-icon-data.svg",
  },
];

const Cards = ({ className }) => {
  return (
    <div className={cn(className, styles.cards)}>
      {items.map((x, index) => (
        <div className={styles.card} key={index}>
          <div className={styles.icon}>
            <img src={x.image} alt="Currency" />
          </div>
          <div className={styles.details}>
            <div className={styles.title}>{x.title}</div>
            <div className={styles.subtitle}>{x.subtitle}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Cards;
