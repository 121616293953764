import React from "react";
import styles from "./ActivityPrivacy.module.sass";
import ActivityCard from "../ActivityCard";

const privacyDetails = [
  {
    title: "HIPAA Compliance",
    details: [
      {
        text: "Adherence to HIPAA guidelines, ensuring confidentiality and security of health data.",
        icon: "arrow-right",
      },
      {
        text: "Regular audits to maintain compliance with healthcare privacy laws.",
        icon: "arrow-right",
      },
    ],
  },
  {
    title: "Advanced Security Measures",
    details: [
      {
        text: "Use of encryption and security protocols to protect data from unauthorized access.",
        icon: "arrow-right",
      },
      {
        text: "Continuous monitoring for potential security threats.",
        icon: "arrow-right",
      },
      {
        text: "Staff training in security best practices.",
        icon: "arrow-right",
      },
    ],
  },
  {
    title: "Privacy by Design",
    details: [
      {
        text: "Incorporating privacy into the architecture of our products.",
        icon: "arrow-right",
      },
      {
        text: "Transparent policies on data use and protection.",
        icon: "arrow-right",
      },
    ],
  },
  {
    title: "Data Encryption and Storage",
    details: [
      {
        text: "Ensuring end-to-end encryption for data in transit and at rest.",
        icon: "arrow-right",
      },
      {
        text: "Secure data storage solutions with restricted access.",
        icon: "arrow-right",
      },
    ],
  },
  {
    title: "User Consent and Control",
    details: [
      {
        text: "Empowering users with control over their personal health information.",
        icon: "arrow-right",
      },
      {
        text: "Clear options for consent and opt-out preferences regarding data sharing.",
        icon: "arrow-right",
      },
    ],
  },
  {
    title: "Regular Security Updates",
    details: [
      {
        text: "Frequent updates to security measures to tackle evolving cyber threats.",
        icon: "arrow-right",
      },
      {
        text: "Proactive approach in applying the latest security patches and updates.",
        icon: "arrow-right",
      },
    ],
  },
];

const ActivityPrivacy = () => {
  return (
    <div className={styles.transfer}>
      <div className={styles.title}>Our Commitment To Privacy</div>
      {privacyDetails.map((x, index) => (
        <ActivityCard className={styles.card} item={x} key={index} />
      ))}
    </div>
  );
};

export default ActivityPrivacy;
