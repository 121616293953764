import React from "react";
import cn from "classnames";
import styles from "./Featured.module.sass";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Icon from "../../../components/Icon";
import Play from "../../../components/Play";
import { articles as initialArticles } from "../../../data/articles";

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Featured = ({ scrollToRef }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 100000,
        settings: "unslick",
      },
      {
        breakpoint: 1179,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const featuredArticles = initialArticles
    .filter((article) => article.featured)
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .slice(0, 4);

  const handleCardClick = (selectedArticle) => {
    localStorage.setItem("selectedArticle", JSON.stringify(selectedArticle));
  };

  return (
    <div
      className={cn("section-bg section-mb0 section-pd", styles.section)}
      ref={scrollToRef}
    >
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <div className={styles.wrap}>
            <h2 className={cn("h2", styles.title)}>Featured</h2>
          </div>
        </div>
        <div className={styles.wrapper}>
          <Slider className={cn("learn-slider", styles.slider)} {...settings}>
            {featuredArticles.map((article, index) =>
              index < 1 ? (
                <Link
                  className={styles.item}
                  key={index}
                  to={`/newsroom-details/${article.title
                    .toLowerCase()
                    .replace(/\s+/g, "-")}`}
                  onClick={() => handleCardClick(article)}
                >
                  <div className={styles.preview}>
                    <img
                      srcSet={`${article.cardImage2x} 2x`}
                      src={article.cardImage}
                      alt="Card"
                    />
                    {article.play && <Play small />}
                  </div>
                  <div className={styles.line}>
                    <div className={styles.wrap}>
                      <div className={styles.subtitle}>{article.title}</div>
                      <div className={styles.content}>{article.subtitle}</div>
                    </div>
                    <button className={cn("button-stroke", styles.button)}>
                      <span>Learn more</span>
                      <Icon name="arrow-right" size="16" />
                    </button>
                  </div>
                </Link>
              ) : (
                <Link
                  className={styles.item}
                  key={index}
                  to={`/newsroom-details/${article.title
                    .toLowerCase()
                    .replace(/\s+/g, "-")}`}
                  onClick={() => handleCardClick(article)}
                >
                  <div className={styles.preview}>
                    <img
                      srcSet={`${article.cardImage2x} 2x`}
                      src={article.cardImage}
                      alt="Card"
                    />
                    {article.play && <Play small />}
                  </div>
                  <div className={styles.details}>
                    <div className={styles.subtitle}>{article.title}</div>
                    <div className={styles.content}>{article.subtitle}</div>
                    {article.date && (
                      <div className={styles.date}>{article.date}</div>
                    )}
                  </div>
                </Link>
              )
            )}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Featured;
