import React, { useState } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Item.module.sass";
import Icon from "../../../../components/Icon";
import Modal from "../../../../components/Modal";

const Item = ({ className, item }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <>
      <Link className={cn(className, styles.item)} onClick={openModal}>
        <div className={styles.icon}>
          <Icon name={item.icon} size="28" />
        </div>
        <div className={styles.title}>{item.title}</div>
        <div className={styles.subtitle}>{item.subtitle}</div>
      </Link>

      <Modal visible={isModalOpen} onClose={closeModal}>
        <div className={styles.body}>
          <div className={styles.ModalLine}>
            <div className={styles.ModalTitle}>{item.title}</div>
          </div>
          <div className={styles.details}>
            <div className={styles.detail}>{item.description}</div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Item;
