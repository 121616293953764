import React from "react";
import cn from "classnames";
import styles from "./Browse.module.sass";
import Slider from "react-slick";
import Item from "./Item";
import Icon from "../../../components/Icon";
import { articles as initialArticles } from "../../../data/articles";

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Browse = ({ headSmall }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const innovationArticles = initialArticles
    .filter((article) => article.categoryText === "Research")
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .slice(0, 9);

  return (
    <div className={cn("section-pd section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.inner}>
          <div className={cn(styles.head, { [styles.headSmall]: headSmall })}>
            <h2 className={cn("h2", styles.title)}>
              Innovations in Healthcare
            </h2>
            <div className={cn("info", styles.info)}>
              Our passion lies in creating innovative solutions that transform
              the healthcare industry. We are committed to improving the quality
              of care, making it more accessible, efficient, and
              patient-centered all while supporting small clinic businesses and
              medical professionals.
            </div>
          </div>
          <div className={styles.wrapper}>
            <Slider className="browse-slider" {...settings}>
              {innovationArticles.map((article, index) => (
                <Item className={styles.item} item={article} key={index} />
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Browse;
