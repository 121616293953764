import React from "react";
import styles from "./ActivityPlatforms.module.sass";
import ActivityCard from "../ActivityCard";

const platformDetails = [
  {
    title: "Web - Clinic Management Platform",
    details: [
      {
        text: "The Web Clinic Management Platform streamlines clinic operations with an easy-to-use interface for booking and managing appointments. It ensures secure access and updating of client health records, adhering to healthcare regulations for privacy. Additionally, the platform enhances operational efficiency by automating the billing process, generating and sending invoices to simplify financial management.",
      },
    ],
  },
  {
    title: "iOS - Client Mobile Application",
    details: [
      {
        text: "The iOS Client Mobile Application provides a convenient way for clients to engage with healthcare services. Users can book appointments anytime and anywhere, access and manage their health records securely on the go, and handle financial transactions seamlessly. The app integrates appointment scheduling with health record management and an easy-to-use payment system, offering a comprehensive healthcare management tool for clients.",
      },
    ],
  },
  {
    title: "Android - Client Mobile Application",
    details: [
      {
        text: "The Android Client Mobile Application offers a user-friendly and flexible approach to healthcare management for clients. It simplifies booking appointments, provides secure access to personal health records, and features a straightforward invoice management and payment system. This app combines convenience with security, ensuring a seamless and efficient user experience for managing healthcare needs.",
      },
    ],
  },
];

const ActivityPlatforms = () => {
  return (
    <div className={styles.transfer}>
      <div className={styles.title}>Our Platforms</div>
      {platformDetails.map((x, index) => (
        <ActivityCard className={styles.card} item={x} key={index} />
      ))}
    </div>
  );
};

export default ActivityPlatforms;
