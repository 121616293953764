import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Subscriptions.module.sass";
import axios from "axios";
import Loader from "../../../components/Loader";
import Checkbox from "../../../components/Checkbox";
import { Link } from "react-router-dom";

const Subscriptions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [displayMessage, setDisplayMessage] = useState("");
  const [email, setEmail] = useState("");
  const [announcement, setAnnouncement] = useState(false);
  const [developer, setDeveloper] = useState(false);
  const [research, setResearch] = useState(false);
  const [career, setCareer] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const requestBody = {
        email: email,
        announcement: announcement,
        developer: developer,
        research: research,
        career: career,
      };
      console.log(requestBody);
      const response = await axios.post(
        "https://kd0xmbip14.execute-api.us-east-2.amazonaws.com/dev/gulaLeadUpdate",
        requestBody
      );
      const responseBody = JSON.parse(response.data.body);
      console.log(responseBody);
      if (responseBody.message.includes("success")) {
        setIsSuccess(true);
        setDisplayMessage(
          "You have successfully updated your email preferences."
        );
      } else {
        setIsSuccess(false);
      }
    } catch (error) {
      console.error(error);
      setDisplayMessage("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const emailParam = queryParams.get("email");
    const announcementParam = queryParams.get("announcement") === "true";
    const developerParam = queryParams.get("developer") === "true";
    const researchParam = queryParams.get("research") === "true";
    const careerParam = queryParams.get("career") === "true";

    if (emailParam) setEmail(emailParam);
    setAnnouncement(announcementParam);
    setDeveloper(developerParam);
    setResearch(researchParam);
    setCareer(careerParam);
  }, []);

  return (
    <>
      <div className={cn("section-pd section-bg section-mb0", styles.section)}>
        <div className={cn("container", styles.container)}>
          <form className={styles.form} action="" onSubmit={handleSubmit}>
            <div className={styles.fieldset}>
              <div className={styles.field}>
                <div className={styles.label}>
                  Current Email Subscription Preferences
                </div>
                <div className={styles.subLabel}>
                  Change your email subscription preferences or update the email
                  address where you'd like to receive email from Gula Health.
                </div>
              </div>
              <div className={styles.field}>
                <Checkbox
                  className={styles.checkbox}
                  value={announcement}
                  onChange={() => setAnnouncement(!announcement)}
                  content="Announcements"
                  note="Stay informed with the latest announcements, tailored recommendations, and essential updates about Gula Health products and services."
                />
              </div>
              <div className={styles.field}>
                <Checkbox
                  className={styles.checkbox}
                  value={developer}
                  onChange={() => setDeveloper(!developer)}
                  content="Developer"
                  note="Get updates, news, and insights about Gula Health APIs, SDKs, and developer tools."
                />
              </div>
              <div className={styles.field}>
                <Checkbox
                  className={styles.checkbox}
                  value={research}
                  onChange={() => setResearch(!research)}
                  content="Research"
                  note="Access the latest findings, ongoing studies, and key publications from Gula Health’s cutting-edge research initiatives."
                />
              </div>
              <div className={styles.field}>
                <Checkbox
                  className={styles.checkbox}
                  value={career}
                  onChange={() => setCareer(!career)}
                  content="Careers"
                  note="Explore new job opportunities and get involved with Gula Health’s mission to improve healthcare for everyone."
                />
              </div>
            </div>
            <div className={styles.subLabel}>
              The address at which you've chosen to receive product news and
              other commercial messages from Gula Health is:{" "}
              <strong>{email}</strong>
            </div>
            <div className={styles.btns}>
              <Link
                className={cn("button-stroke button-small", styles.button)}
                to="/"
              >
                Cancel
              </Link>
              <button
                className={cn("button-small", styles.button)}
                disabled={isLoading}
              >
                {isLoading && (
                  <Loader className={styles.loader} color="white" />
                )}
                Update
              </button>
            </div>
            {displayMessage && (
              <div className={styles.errorMessage}>
                <strong>{displayMessage}</strong>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};

export default Subscriptions;
