import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import styles from "./Page.module.sass";
import CSHeader from "../CSHeader";
import CSFooter from "../CSFooter";

const CSPage = ({
  separatorHeader,
  children,
  fooferHide,
  wide,
  notAuthorized,
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllBodyScrollLocks();
  }, [pathname]);

  return (
    <>
      <div className={styles.page}>
        <CSHeader
          separatorHeader={separatorHeader}
          wide={wide}
          notAuthorized={notAuthorized}
        />
        <div className={styles.inner}>{children}</div>
        {!fooferHide && <CSFooter />}
      </div>
    </>
  );
};

export default CSPage;
