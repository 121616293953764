import React, { useState, useEffect } from "react";
import PrivacyLayout from "../../components/PrivacyLayout";
import styles from "./PrivacyPolicy.module.sass";
import { privacyPolicy as policies } from "../../data/privacy-policy";

const PrivacyPolicy = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const policyTitles = policies.map((policy) => policy.title);

  const handleScroll = () => {
    let newActiveIndex = 0;
    policies.forEach((policy, index) => {
      const element = document.getElementById(`policy-${index}`);
      if (
        element &&
        element.getBoundingClientRect().top < window.innerHeight / 1.5
      ) {
        newActiveIndex = index;
      }
    });
    setActiveIndex(newActiveIndex);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <PrivacyLayout
        title="Privacy Policy"
        items={policyTitles}
        activeIndex={activeIndex}
      >
        <div>
          {policies.map((policy, index) => (
            <div key={index} id={`policy-${index}`} className={styles.policy}>
              <div className={styles.title}>{policy.title}</div>
              <div className={styles.subtitle}>{policy.subtitle}</div>
              <ul>
                {policy.descriptions.map((description, descIndex) => (
                  <li className={styles.description} key={descIndex}>
                    {description}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </PrivacyLayout>
    </>
  );
};

export default PrivacyPolicy;
