import React from "react";
import cn from "classnames";
import styles from "./Focus.module.sass";
import Slider from "react-slick";
import Icon from "../../../components/Icon";
import Item from "./Item";

const items = [
  // {
  //   title: "NFC Biosensor Advancements",
  //   subtitle: "Real-time Health Monitoring",
  //   content:
  //     "Biosensor technology is advancing rapidly. We are developing new biosensors to track vital signs and detect health conditions.",
  //   src: "/content/research-nfc-biosensor.png",
  //   srcSet: "/content/research-nfc-biosensor.png 2x",
  // },
  {
    title: "Project Cuneiform",
    subtitle: "Text based Machine Learning",
    content:
      "We are developing a large language machine learning model to analyze and understand health data, while also providing insights and recommendations with integrations throughout our platforms.",
    src: "/content/research-chat.png",
    srcSet: "/content/research-chat.png 2x",
  },
  {
    title: "Smartwatch Synchronization",
    subtitle: "Seamless Health Monitoring at Your Wrist",
    content:
      "Integrating smartwatches and biosensors with our care platform for real-time health monitoring and medical assistance.",
    src: "/content/research-smartwatch.png",
    srcSet: "/content/research-smartwatch.png 2x",
  },
  {
    title: "Project Nimrud",
    subtitle: "Vision AI Assistant",
    content:
      "We are developing a vision AI assistant for identitfying health conditions, medications, and more. Using just a smartphone camera, our AI assistant will provide insights and recommendations with integrations throughout our platforms.",
    src: "/content/research-vision.png",
    srcSet: "/content/research-vision.png 2x",
  },

  // {
  //   title: "CASPR Technology",
  //   subtitle: "Cannabinoid Detection for Safer Consumption",
  //   content:
  //     "We are developing a new biosensor to detect cannabinoids in cannabis products.",
  //   src: "/content/research-caspr.png",
  //   srcSet: "/content/research-caspr.png 2x",
  // },
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Focus = ({ scrollToRef }) => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          nextArrow: (
            <SlickArrow>
              <Icon name="arrow-next" size="14" />
            </SlickArrow>
          ),
          prevArrow: (
            <SlickArrow>
              <Icon name="arrow-prev" size="14" />
            </SlickArrow>
          ),
        },
      },
      {
        breakpoint: 600,
        settings: {
          infinite: false,
          slidesToShow: 1,
          nextArrow: (
            <SlickArrow>
              <Icon name="arrow-next" size="14" />
            </SlickArrow>
          ),
          prevArrow: (
            <SlickArrow>
              <Icon name="arrow-prev" size="14" />
            </SlickArrow>
          ),
        },
      },
    ],
  };

  return (
    <div className={cn("section-pd mb-0", styles.section)} ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>Focus areas</h2>
          <div className={cn("info", styles.info)}>
            Our research is focused on developing innovative technologies that
            will improve healthcare access and quality. From developing machine
            learning health tools to biosensor advancements, we are committed to
            creating solutions that will transform healthcare.
          </div>
        </div>
        <div className={styles.wrapper}>
          <Slider className={cn("live-slider")} {...settings}>
            {items.map((x, index) => (
              <Item className={styles.item} item={x} key={index} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Focus;
