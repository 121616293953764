import React from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import styles from "./PageList.module.sass";

const PageList = () => {
  return (
    <div className={styles.page}>
      <div className={cn("container", styles.container)}>
        <p>
          <Link to="/">Home</Link>
        </p>
        <p style={{ marginTop: 40 }}>
          <Link to="/developers">Developers</Link>
        </p>
        <p style={{ marginTop: 40 }}>
          <Link to="/care">Care</Link>
        </p>
        <p style={{ marginTop: 40 }}>
          <Link to="/research">Research</Link>
        </p>
        <p>
          <Link to="/research-photos">Research Photos</Link>
        </p>
        <p style={{ marginTop: 40 }}>
          <Link to="/support">Support</Link>
        </p>
        <p style={{ marginTop: 40 }}>
          <Link to="/about">About</Link>
        </p>
        <p style={{ marginTop: 40 }}>
          <Link to="/activity">Activity</Link>
        </p>
        <p style={{ marginTop: 40 }}>
          <Link to="/careers">Careers</Link>
        </p>
        <p style={{ marginTop: 40 }}>
          <Link to="/newsroom">Newsroom</Link>
        </p>
        <p>
          <Link to="/newsroom-details">Newsroom Details</Link>
        </p>
        <p style={{ marginTop: 40 }}>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </p>
        <p style={{ marginTop: 40 }}>
          <Link to="/terms-legal">Terms & Legal</Link>
        </p>
        <p style={{ marginTop: 40 }}>
          <Link to="/contact">Contact</Link>
        </p>
        <p style={{ marginTop: 40 }}>
          <Link to="/404">404</Link>
        </p>
        <p style={{ marginTop: 40 }}>
          <Link to="/coming-soon">Coming Soon</Link>
        </p>
      </div>
    </div>
  );
};

export default PageList;
