import React, { useState } from "react";
import cn from "classnames";
import styles from "./Item.module.sass";
import Modal from "../../../../components/Modal";

const Item = ({ className, item }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <>
      <div className={cn(className, styles.item)} onClick={openModal}>
        <div className={styles.preview}>
          <img srcSet={`${item.srcSet} 2x`} src={item.src} alt="Live" />
        </div>
        <div className={styles.subtitle}>{item.title}</div>
        <div className={styles.text}>{item.subtitle}</div>
      </div>
      <Modal visible={isModalOpen} onClose={closeModal}>
        <div className={styles.body}>
          <div className={styles.ModalLine}>
            <div className={styles.ModalTitle}>{item.title}</div>
          </div>
          <div className={styles.details}>
            <div className={styles.detail}>{item.content}</div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Item;
