import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";
import Icon from "../../../components/Icon";

const Main = ({ scrollToRef }) => {
  return (
    <div className={cn("section-mb0", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <div className={cn("stage", styles.stage)}>
            Your Health, Our Priority
          </div>
          <h1 className={cn("h1", styles.title)}>How can we help</h1>
          <div className={styles.text}>
            Dedicated to providing services that enchance our lives.
          </div>
          {/* <div className={styles.contactItem}>
            <Icon name="phone" size="24" />
            <span className={styles.contactText}>+1.613.218.6527</span>
          </div> */}
          <div className={styles.contactItem}>
            <Icon name="email" size="24" />
            <span className={styles.contactText}>support@gulahealth.com</span>
          </div>
          <div className={styles.btns}>
            <button
              className={cn("button", styles.button)}
              onClick={() =>
                scrollToRef.current.scrollIntoView({ behavior: "smooth" })
              }
            >
              Contact Us
            </button>
          </div>
        </div>
        <div className={styles.bg}>
          <img
            srcSet="/content/support-main-1@2x.png 2x"
            src="/content/support-main-1.png"
            alt="Cards"
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
