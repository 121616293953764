import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Main.module.sass";
import { NavLink } from "react-router-dom";
import Modal from "../../../components/Modal";
import NotifyMe from "../NotifyMe";

const Main = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const [animate, setAnimate] = useState(false);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const words = ["Care.", "Sense.", "Pharma.", "Fit.", "Store."];
    let index = 0;

    const animatedTextElement = document.getElementById("animatedText");
    animatedTextElement.textContent = words[index];

    setTimeout(() => {
      setVisible(false);

      setTimeout(() => {
        setAnimate(true);
        const changeWord = () => {
          index = (index + 1) % words.length;
          animatedTextElement.textContent = words[index];
        };
        changeWord();

        setInterval(changeWord, 5000);
      }, 1000);
    }, 3000);
  }, []);

  return (
    <div className={cn(styles.section)}>
      <div className={styles.background}>
        <div className={styles.blob}></div>
        <div className={styles.blob}></div>
        <div className={styles.blob}></div>
      </div>
      <div className={cn("container", styles.container)}>
        <h1 className={cn("h1", styles.title)}>
          This is Gula Health{" "}
          <span
            id="animatedText"
            className={cn(styles.animatedText, {
              [styles.animate]: animate,
              [styles.hidden]: !visible,
            })}
          ></span>
        </h1>
        <div className={styles.info}>
          We're on a mission to build tools that will improve access and quality
          of healthcare for all of Humanity.
        </div>
        <div className={cn(styles.wrap)}></div>
        <div className={styles.control}>
          <NavLink
            className={cn("button-stroke", styles.button)}
            onClick={openModal}
          >
            Notify Me
          </NavLink>
        </div>
      </div>
      <Modal visible={isModalOpen} onClose={closeModal}>
        <NotifyMe onClose={closeModal} />
      </Modal>
    </div>
  );
};

export default Main;
