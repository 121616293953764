import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Activity.module.sass";
import Dropdown from "../../components/Dropdown";
import Table from "./Table";
import Info from "./Info";
import { activity as table } from "../../data/activity";

const navigation = ["All", "Web", "Mobile", "API", "Other"];

const Activity = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [sorting, setSorting] = useState(navigation[0]);
  const [filteredTable, setFilteredTable] = useState(table);

  const filterTable = () => {
    const filter = navigation[activeIndex];
    const filteredData = table.filter(
      (item) => filter === "All" || item.platform === filter
    );
    setFilteredTable(filteredData);

    if (sorting !== filter) {
      setSorting(filter);
    }
  };

  useEffect(() => {
    filterTable();
  }, [activeIndex, sorting]);

  return (
    <div className={styles.activity}>
      <div className={cn("container", styles.container)}>
        <div className={styles.body}>
          <div className={styles.top}>
            <div className={styles.nav}>
              {navigation.map((x, index) => (
                <button
                  className={cn(styles.link, {
                    [styles.active]: index === activeIndex,
                  })}
                  onClick={() => setActiveIndex(index)}
                  key={index}
                >
                  {x}
                </button>
              ))}
            </div>
            <div className={styles.dropdown}>
              <Dropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                value={sorting}
                setValue={setSorting}
                options={navigation}
              />
            </div>
          </div>
          <div className={styles.line}>
            <h4 className={cn("h4", styles.title)}>Activity</h4>
          </div>
          <Table className={styles.table} items={filteredTable} />
        </div>
        <Info className={styles.actions} />
      </div>
    </div>
  );
};

export default Activity;
