import React from "react";
import Question from "./Question";

const Contact = () => {
  return (
    <>
      <Question />
    </>
  );
};

export default Contact;
