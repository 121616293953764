import React from "react";
import cn from "classnames";
import styles from "./NewsCard.module.sass";
import Play from "../Play";
import { Link } from "react-router-dom";

const NewsCard = ({ className, item }) => {
  const handleCardClick = () => {
    localStorage.setItem("selectedArticle", JSON.stringify(item));
  };

  return (
    <Link
      className={cn(className, styles.card)}
      to={`/newsroom-details/${item.title.toLowerCase().replace(/\s+/g, "-")}`}
      onClick={handleCardClick}
    >
      <div className={styles.preview}>
        {item.categoryText && (
          <div
            className={cn(
              "category",
              {
                "category-purple": item.category === "purple",
              },
              {
                "category-green": item.category === "green",
              },
              {
                "category-blue": item.category === "blue",
              },
              styles.category
            )}
          >
            {item.categoryText}
          </div>
        )}
        <img
          srcSet={`${item.cardImage2x} 2x`}
          src={item.cardImage}
          alt="Card"
        />
        {item.play && <Play className="play" small />}
      </div>
      <div className={styles.body}>
        <div className={styles.details}>
          <div className={styles.title}>{item.title}</div>
          <div className={styles.author}>{item.author}</div>
        </div>
      </div>
    </Link>
  );
};

export default NewsCard;
