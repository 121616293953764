import React, { useRef } from "react";
import Main from "./Main";
import Focus from "./Focus";
import Brands from "./Brands";
import Catalog from "./Catalog";
import Images from "./Images";
import Newsletter from "./Newsletter";

const Research = () => {
  const scrollToRef = useRef(null);

  return (
    <>
      <Main scrollToRef={scrollToRef} />
      <Focus scrollToRef={scrollToRef} />
      <Brands />
      <Catalog />
      {/* <Images /> */}
      <Newsletter />
    </>
  );
};

export default Research;
