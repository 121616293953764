import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Download.module.sass";

const items = [
  {
    title: "Appstore",
    content: "Coming soon to iOS",
    image: "/content/apple.svg",
    url: "/",
  },
  {
    title: "Google Play",
    content: "Coming soon to Android",
    image: "/content/google-play.svg",
    url: "/",
  },
  {
    title: "Web Platform",
    content: "Access Clinic Portal",
    image: "/content/web.svg",
    url: "/",
    // url: "https://care.gulahealth.com",
  },
];
const Download = () => {
  return (
    <div className={cn("section-mb0", styles.download)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.bg}>
          <img
            srcSet="/content/download-pic@2x.png 2x"
            src="/content/download-pic.png"
            alt="Download"
          />
        </div>
        <div className={styles.wrap}>
          <h2 className={cn("h2", styles.title)}>Coming Soon</h2>
          <div className={styles.info}>
            Your health, our priority. Conveniently access services on the go or
            from the clinic.
          </div>
          <div className={styles.list}>
            {items.map((x, index) => {
              // if (x.title === "Web Platform") {
              if (x.title === "Web ") {
                return (
                  <a
                    className={styles.item}
                    key={index}
                    href={x.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className={styles.icon}>
                      <img src={x.image} alt="Logo" />
                    </div>
                    <div className={styles.details}>
                      <div className={styles.content}>{x.content}</div>
                      <div className={styles.subtitle}>{x.title}</div>
                    </div>
                  </a>
                );
              }

              return (
                <Link className={styles.item} key={index} to={x.url}>
                  <div className={styles.icon}>
                    <img src={x.image} alt="Logo" />
                  </div>
                  <div className={styles.details}>
                    <div className={styles.content}>{x.content}</div>
                    <div className={styles.subtitle}>{x.title}</div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Download;
