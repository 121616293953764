import React from "react";
import styles from "./CareersCard.module.sass";
import Icon from "../Icon";

const CareersCard = ({ item }) => {
  return (
    <>
      <div className={styles.body}>
        <div className={styles.line}>
          <div className={styles.title}>{item.title}</div>
        </div>
        <div className={styles.options}>
          {item?.options?.map((x, index) => (
            <div className={styles.option} key={index}>
              <Icon name={x.icon} size="16" />
              {x?.title}
            </div>
          ))}
        </div>
        <div className={styles.foot} />
      </div>
    </>
  );
};

export default CareersCard;
