import React from "react";
import cn from "classnames";
import styles from "./Footer.module.sass";

const CSFooter = () => {
  return (
    <div className={styles.footer}>
      <div className={cn("container", styles.container)}>
        <div className={styles.bottom}>
          <div className={styles.copyright}>
            Copyright © 2024 Gula Health Inc. All rights reserved.
          </div>
        </div>
      </div>
    </div>
  );
};

export default CSFooter;
