import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./ResearchCatalogCard.module.sass";

const ResearchCatalogCard = ({ className, item }) => {
  const handleCardClick = () => {
    localStorage.setItem("selectedArticle", JSON.stringify(item));
  };
  return (
    <Link
      className={cn(className, styles.destination)}
      to={`/newsroom-details/${item.title.toLowerCase().replace(/\s+/g, "-")}`}
      onClick={handleCardClick}
    >
      <div className={styles.preview}>
        <img
          srcSet={`${item.cardImage2x} 2x`}
          src={item.cardImage}
          alt="City"
        />
        {/* {item.date && (
          <div
            className={cn("status", styles.category, {
              "status-black": item.category === "black",
            })}
          >
            {item.date}
          </div>
        )} */}
      </div>
      <div className={styles.title}>{item.title}</div>
      {/* <div className={styles.content}>{item.subtitle}</div> */}
    </Link>
  );
};

export default ResearchCatalogCard;
