import React from "react";
import Main from "./Main";
import Features from "./Features";
import Download from "./Download";
import Values from "./Values";

const HomePage = () => {
  return (
    <>
      <Main />
      <Features />
      <Download />
      <Values />
    </>
  );
};

export default HomePage;
