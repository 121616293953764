import { Routes, Route } from "react-router-dom";
import "react-dates/lib/css/_datepicker.css";
import "./styles/app.sass";
import Page from "./components/Page";
import PageList from "./screens/PageList";
import Contact from "./screens/Contact";
import Activity from "./screens/Activity";
import HomePage from "./screens/Home";
import Developers from "./screens/Developers";
import Care from "./screens/Care";
import Support from "./screens/Support";
import About from "./screens/About";
import Careers from "./screens/Careers";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import TermsLegal from "./screens/TermsLegal";
import Newsroom from "./screens/Newsroom";
import NewsroomDetails from "./screens/NewsroomDetails";
import ResearchPhotos from "./components/ResearchPhotos";
import Research from "./screens/Research";
import ComingSoon from "./screens/ComingSoon";
import CSPage from "./components/CSPage";
import FRHomePage from "./ca-fr/screens/Home";
import FRPage from "./ca-fr/components/Page";
import Subscriptions from "./screens/General/Subscriptions";

function App() {
  return (
    <Routes>
      <Route path="/">
        <Route
          index
          element={
            <Page>
              <HomePage />
            </Page>
          }
        />
        <Route
          path="coming-soon"
          element={
            <CSPage>
              <ComingSoon />
            </CSPage>
          }
        />
        <Route
          path="developers"
          element={
            <Page>
              <Developers />
            </Page>
          }
        />
        {/* <Route
          path="care"
          element={
            <Page>
              <Care />
            </Page>
          }
        /> */}
        <Route
          path="research-photos"
          element={
            <Page>
              <ResearchPhotos />
            </Page>
          }
        />
        <Route
          path="support"
          element={
            <Page>
              <Support />
            </Page>
          }
        />
        <Route
          path="about"
          element={
            <Page separatorHeader>
              <About />
            </Page>
          }
        />
        <Route
          path="activity"
          element={
            <Page>
              <Activity />
            </Page>
          }
        />
        <Route
          path="research"
          element={
            <Page>
              <Research />
            </Page>
          }
        />
        <Route
          path="careers"
          element={
            <Page separatorHeader>
              <Careers />
            </Page>
          }
        />
        <Route
          path="newsroom"
          element={
            <Page separatorHeader>
              <Newsroom />
            </Page>
          }
        />

        <Route
          path="newsroom-details/:articleName"
          element={
            <Page separatorHeader>
              <NewsroomDetails />
            </Page>
          }
        />
        <Route
          path="privacy-policy"
          element={
            <Page separatorHeader>
              <PrivacyPolicy />
            </Page>
          }
        />
        <Route
          path="terms-legal"
          element={
            <Page separatorHeader>
              <TermsLegal />
            </Page>
          }
        />
        <Route
          path="contact"
          element={
            <Page>
              <Contact />
            </Page>
          }
        />
        <Route
          path="subscriptions"
          element={
            <Page>
              <Subscriptions />
            </Page>
          }
        />
        <Route path="/pagelist" element={<PageList />} />
      </Route>
      {/* <Route path="/ca/fr">
        <Route
          index
          element={
            <FRPage>
              <FRHomePage />
            </FRPage>
          }
        />
      </Route> */}
    </Routes>
  );
}

export default App;
