import React from "react";
import cn from "classnames";
import styles from "./Vision.module.sass";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import Icon from "../../../components/Icon";

const gallery = [
  {
    src: "/content/about-us-gallery-1.png",
    srcSet: "/content/about-us-gallery-1@2x.png",
  },
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Vision = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    cssEase: "cubic-bezier(0.7, 0, 0.3, 1)",
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
  };

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.list}>
              <h2 className={cn("h2", styles.title)}>
                Our Vision for the Future of Healthcare
              </h2>
              <div className={styles.text}>
                Envisioning a world where quality healthcare is within
                everyone's reach, we are committed to transforming healthcare
                access globally. Our focus is on providing exceptional care,
                simplifying the journey to wellness with user-friendly booking
                systems, and ensuring you can easily locate nearby clinics and
                medical professionals. This is our healthcare, reimagined for
                simplicity and excellence.
              </div>
            </div>
            {/* <Link className={cn("button", styles.button)} to="/stays-category">
              Read Our Plan
            </Link> */}
          </div>

          <div className={styles.col}>
            <div className={styles.wrapper}>
              <Slider className="travel-slider" {...settings}>
                {gallery.map((item, index) => (
                  <div className={styles.gallery} key={index}>
                    <div className={cn("travel-bg", styles.bg)}>
                      <img
                        srcSet={`${item.srcSet} 2x`}
                        src={item.src}
                        alt="Travel"
                      />
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;
