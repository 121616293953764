import React from "react";
import cn from "classnames";
import styles from "./Preview.module.sass";
import Icon from "../../../components/Icon";
import CareersCard from "../../../components/CareersCard";

const compensationDetails = [
  {
    title: "Competitive Salary",
    options: [
      { title: "Annual Performance Bonuses", icon: "dollar-sign" },
      { title: "Regular Salary Reviews", icon: "chart-line" },
    ],
  },
  {
    title: "Health Benefits",
  },
  {
    title: "Equity or Stock Options",
  },
  {
    title: "Continuing Education and Training Programs",
  },
  {
    title: "Remote Work and Flexible Hours",
  },
];

const whatWeLookFor = [
  {
    title: "A Passion for Healthcare",
    options: [
      { title: "Empathy and Compassion", icon: "heart" },
      { title: "Innovative Thinking", icon: "lightbulb" },
    ],
  },
  {
    title: "Team Collaboration",
    options: [
      { title: "Strong Team Players", icon: "users" },
      { title: "Excellent Communication Skills", icon: "comment-dots" },
    ],
  },
  {
    title: "Leadership Qualities",
  },
  {
    title: "Problem-Solving Skills",
  },
  {
    title: "Adaptability",
  },
];

const Preview = ({ className, onClose }) => {
  return (
    <div className={cn(className, styles.preview)}>
      <div className={styles.inner}>
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="14" />
        </button>
        <div className={styles.title}>Compensation & Benefits</div>
        {compensationDetails.map((x, index) => (
          <CareersCard className={styles.card} item={x} key={index} />
        ))}

        <div className={styles.title}>What we look for</div>
        {whatWeLookFor.map((x, index) => (
          <CareersCard className={styles.card} item={x} key={index} />
        ))}
      </div>
    </div>
  );
};

export default Preview;
