import React, { useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link, NavLink, useLocation } from "react-router-dom";
import Image from "../Image";
import Dropdown from "./Dropdown";
import Theme from "../Theme";
import Language from "./Language";

const languages = [
  {
    title: "English",
    country: "Canada",
    url: "/",
  },
  // {
  //   title: "Français",
  //   country: "Canada",
  //   url: "/ca/fr",
  // },
];

const navigation = [
  {
    title: "Developers",
    url: "/developers",
  },
  {
    title: "Care",
    url: "/coming-soon",
  },
  // {
  //   title: "Care",
  //   url: "/care",
  // },
  {
    title: "Research",
    url: "/research",
  },
  {
    title: "Support",
    url: "/support",
  },
  // {
  //   title: "Sense",
  //   url: "/sense",
  // },
  // {
  //   title: "Fitness",
  //   url: "/fitness",
  // },
  // {
  //   title: "Store",
  //   url: "/store",
  // },
  // {
  //   title: "Sense",
  //   dropdown: [
  //     {
  //       title: "Sense 1",
  //       icon: "user",
  //       url: "/sense1",
  //     },
  //     {
  //       title: "Sense 2",
  //       icon: "user",
  //       url: "/sense2",
  //     },
  //   ],
  // },
];

const Header = ({ headerWide }) => {
  const [visibleNav, setVisibleNav] = useState(false);
  const { pathname } = useLocation();

  return (
    <header className={cn(styles.header, { [styles.wide]: headerWide })}>
      <div className={cn("container", styles.container)}>
        <Link
          className={styles.logo}
          to="/"
          onClick={() => setVisibleNav(false)}
        >
          <Image
            className={styles.picDesktop}
            src="/logo/logo-dark.svg"
            srcDark="/logo/logo-light.svg"
            alt="gula"
          />
          <Image
            className={styles.picMobile}
            src="/logo/logo-icon-dark.svg"
            srcDark="/logo/logo-icon-light.svg"
            alt="gula"
          />
        </Link>
        <div className={styles.wrapper}>
          <div
            className={cn(styles.wrap, {
              [styles.visible]: visibleNav,
            })}
          >
            <nav className={styles.nav}>
              {navigation.map((x, index) =>
                x.url === "/coming-soon" ? (
                  <a
                    className={cn(styles.item, {
                      [styles.active]: pathname === x.url,
                    })}
                    href={x.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={index}
                    onClick={() => setVisibleNav(false)}
                  >
                    {x.title}
                  </a>
                ) : x.dropdown ? (
                  <Dropdown
                    className={styles.dropdown}
                    key={index}
                    item={x}
                    setValue={setVisibleNav}
                  />
                ) : (
                  <NavLink
                    className={cn(styles.item, {
                      [styles.active]: pathname === x.url,
                    })}
                    to={x.url}
                    key={index}
                    onClick={() => setVisibleNav(false)}
                  >
                    {x.title}
                  </NavLink>
                )
              )}

              {/* {navigation.map((x, index) =>
                x.dropdown ? (
                  <Dropdown
                    className={styles.dropdown}
                    key={index}
                    item={x}
                    setValue={setVisibleNav}
                  />
                ) : (
                  <NavLink
                    className={cn(styles.item, {
                      [styles.active]: pathname === x.url,
                    })}
                    onClick={() => setVisibleNav(false)}
                    to={x.url}
                    key={index}
                  >
                    {x.title}
                  </NavLink>
                )
              )} */}
            </nav>
            {/* <NavLink
              className={cn("button-stroke", styles.button, {
                [styles.active]: pathname === "/clinic",
              })}
              to="/clinic"
              onClick={() => setVisibleNav(false)}
            >
              Download App
            </NavLink>
            <NavLink
              className={cn("button-stroke", styles.button, {
                [styles.active]: pathname === "/clinic",
              })}
              to="/clinic"
              onClick={() => setVisibleNav(false)}
            >
              Portal
            </NavLink> */}
          </div>
          <div className={styles.control}>
            {/* <NavLink
              className={cn("button-stroke button-small", styles.button)}
              to="/clinic"
            >
              Download App
            </NavLink>
            <NavLink
              className={cn("button-stroke button-small", styles.button)}
              to="/clinic"
            >
              Portal
            </NavLink> */}

            <Theme className={styles.theme} icon />

            <Language className={styles.language} items={languages} />
          </div>
          <button
            className={cn(styles.burger, {
              [styles.active]: visibleNav,
            })}
            onClick={() => setVisibleNav(!visibleNav)}
          ></button>
        </div>
      </div>
    </header>
  );
};

export default Header;
