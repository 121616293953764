import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Catalog.module.sass";
import Icon from "../../../components/Icon";
import NewsCard from "../../../components/NewsCard";
import { articles as initialArticles } from "../../../data/articles";

const Catalog = ({ excludeTitle }) => {
  const [displayedArticles, setDisplayedArticles] = useState([]);

  useEffect(() => {
    updateRandomArticles();
  }, [excludeTitle]);

  const updateRandomArticles = () => {
    const newArticles = [];
    const availableArticles = initialArticles.filter(
      (article) => article.title !== excludeTitle
    );
    for (let i = 0; i < 3 && availableArticles.length; i++) {
      const randomIndex = Math.floor(Math.random() * availableArticles.length);
      newArticles.push(availableArticles.splice(randomIndex, 1)[0]);
    }
    setDisplayedArticles((prevArticles) => [...prevArticles, ...newArticles]);
  };

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.list}>
          {displayedArticles.map((x, index) => (
            <NewsCard className={styles.card} item={x} key={index} />
          ))}
        </div>
        {/* <div className={styles.btns}>
          <button
            className={cn("button-stroke button-small", styles.button)}
            onClick={updateRandomArticles}
          >
            <span>View more</span>
            <Icon name="arrow-down" size="16" />
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default Catalog;
