export const termsLegal = [
  {
    title: "Terms and Conditions",
    subtitle: "Welcome to Gula Health!",
    descriptions: [
      "These terms and conditions outline the rules and regulations for the use of Gula Health Inc.'s Website, located at www.gulahealth.com.",
      "By accessing this website, we assume you accept these terms and conditions. Do not continue to use Gula Health if you do not agree to all of the terms and conditions stated on this page.",
    ],
  },
  {
    title: "Cookies Policy",
    subtitle: "Use of Cookies on Our Website",
    descriptions: [
      "The website uses cookies to personalize and enhance your online experience. By accessing Gula Health, you agreed to the use of necessary cookies.",
      "Cookies are small text files placed on your device by web servers. They cannot be used to run programs or deliver viruses. They are unique to you and can only be read by the web server in the domain that issued them.",
    ],
  },
  {
    title: "License",
    subtitle: "Intellectual Property Rights",
    descriptions: [
      "Unless otherwise stated, Gula Health Inc. and/or its licensors own the intellectual property rights for all material on Gula Health. All intellectual property rights are reserved.",
      "You must not replicate, duplicate, copy, sell, rent, or redistribute content from Gula Health without our express written consent.",
    ],
  },
  {
    title: "User Comments",
    subtitle: "Comments and Opinions on Our Website",
    descriptions: [
      "Gula Health Inc. allows users to post comments, which do not reflect the views and opinions of Gula Health Inc., its agents, or affiliates.",
      "Gula Health Inc. reserves the right to monitor and remove comments that are inappropriate, offensive, or breach these Terms and Conditions.",
    ],
  },
  {
    title: "Hyperlinking to Our Content",
    subtitle: "Guidelines for Linking to Our Website",
    descriptions: [
      "Certain organizations may link to our Website without prior approval, including government agencies, search engines, news organizations, and online directory distributors.",
      "We may approve link requests from other reputable organizations, provided the link is not deceptive, implies sponsorship, or fits within the context of the linking party's site.",
    ],
  },
  {
    title: "Content Liability",
    subtitle: "Our Responsibility for Content",
    descriptions: [
      "We shall not be held responsible for any content that appears on your Website linked to ours.",
      "You agree to protect and defend us against all claims raised on your Website.",
    ],
  },
  {
    title: "Reservation of Rights",
    subtitle: "Rights Regarding Content and Links to Our Website",
    descriptions: [
      "We reserve the right to request the removal of all links or any particular link to our Website.",
      "By linking to our Website, you agree to be bound to these terms and conditions.",
    ],
  },
  {
    title: "Disclaimer",
    subtitle: "Legal Disclaimer",
    descriptions: [
      "While we endeavor to ensure that the information on this website is correct, we do not warrant its completeness or accuracy.",
      "We will not be liable for any loss or damage of any nature related to your use of our website.",
    ],
  },
];
