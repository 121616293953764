import React, { useState } from "react";
import cn from "classnames";
import styles from "./Newsletter.module.sass";
import Image from "../../../components/Image";
import Form from "../../../components/Form";
import axios from "axios";

const gallery = [
  {
    src: "/content/research-newsletter-1.png",
    srcSet: "/content/research-newsletter-1@2x.png",
    srcDark: "/content/research-newsletter-dark-1.png",
    srcSetDark: "/content/research-newsletter-dark-1@2x.png",
  },
];

const items = [
  {
    title: "AI in Healthcare",
    color: "#58C27D",
  },
  {
    title: "Health Monitoring Innovations",
    color: "#92A5EF",
  },
  {
    title: "New Product Announcements",
    color: "#F4B740",
  },
];

const Newsletter = ({ classSection }) => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [displayMessage, setDisplayMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setDisplayMessage("");
    try {
      const requestBody = {
        email: email,
        type: "research",
        source: "gulahealth-website-research",
      };
      const response = await axios.post(
        "https://0gbi0gwcj1.execute-api.us-east-2.amazonaws.com/dev/gulaLead",
        requestBody
      );
      const responseBody = JSON.parse(response.data.body);
      console.log(responseBody);
      if (responseBody.message.includes("Duplicate email")) {
        setIsSuccess(false);
        setDisplayMessage(responseBody.message);
      } else {
        setIsSuccess(true);
        setDisplayMessage(
          "You have successfully subscribed to our newsletter!"
        );
      }
    } catch (error) {
      console.error(error);
      setDisplayMessage("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={cn("section-pd", classSection, styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <h2 className={cn("h2", styles.title)}>
            Join Our Research Newsletter{" "}
          </h2>
          <div className={styles.info}>
            Stay up to date with the progress of our research and development by
            subscribing to our newsletter.
          </div>
          <div className={styles.list}>
            {items.map((x, index) => (
              <div className={styles.item} key={index}>
                <div
                  className={styles.number}
                  style={{ backgroundColor: x.color }}
                >
                  0{index + 1}
                </div>
                <div className={styles.text}>{x.title}</div>
              </div>
            ))}
          </div>
          {isSuccess ? (
            <div className={styles.successMessage}>
              <strong>{displayMessage}</strong>
            </div>
          ) : (
            <>
              <Form
                className={styles.form}
                value={email}
                setValue={setEmail}
                onSubmit={handleSubmit}
                placeholder="Enter your email"
                type="email"
                name="email"
                icon="arrow-next"
                loading={isLoading}
              />
              {displayMessage && (
                <div className={styles.errorMessage}>
                  <strong>{displayMessage}</strong>
                </div>
              )}
            </>
          )}
        </div>
        <div className={styles.gallery}>
          {gallery.map((x, index) => (
            <div className={styles.preview} key={index}>
              <Image
                srcSet={`${x.srcSet} 2x`}
                srcSetDark={`${x.srcSetDark} 2x`}
                src={x.src}
                srcDark={x.srcDark}
                alt="Newsletter"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
