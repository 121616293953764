import React from "react";
import cn from "classnames";
import styles from "./Table.module.sass";
import { Link } from "react-router-dom";

const Table = ({ className, items }) => {
  return (
    <div className={cn(className, styles.table)}>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className="sorting">Platform</div>
        </div>
        <div className={styles.col}>
          <div>Version</div>
        </div>
        <div className={styles.col}>Notes</div>
        <div className={styles.col}>
          <div className="sorting">Date</div>
        </div>
      </div>
      {items.map((x, index) => (
        <Link className={styles.row} key={index} to={x.url}>
          <div className={styles.col}>
            <div
              className={cn(
                {
                  "category-blue": x.platform === "Web",
                  "category-green": x.platform === "Mobile",
                  "category-orange": x.platform === "API",
                },
                "[class^='category']"
              )}
            >
              {x.platform}
            </div>
          </div>
          <div className={styles.col}>
            <div className={styles.label}>Version</div>
            {x.version}
          </div>
          <div className={styles.col}>
            <div className={styles.label}>Notes</div>
            {x.notes}
          </div>
          <div className={styles.col}>
            <div className={styles.label}>Date</div>
            {x.date}
          </div>
        </Link>
      ))}
    </div>
  );
};

export default Table;
