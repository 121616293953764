import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";
import ScrollButton from "../../../components/ScrollButton";

const Main = ({ scrollToRef }) => {
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.preview}>
          <picture>
            <source
              media="(max-width: 767px)"
              srcSet="/content/lab-scope.jpeg"
            />
            <img src="/content/lab-scope.jpeg" alt="Main" />
          </picture>
          <div className={styles.wrap}>
            <h1 className={cn("hero", styles.title)}>Sensing, Redefined</h1>
            <div className={cn("info", styles.info)}>
              Exploring New Frontiers in Health.
            </div>
            <ScrollButton
              onScroll={() =>
                scrollToRef.current.scrollIntoView({ behavior: "smooth" })
              }
              className={styles.scroll}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
