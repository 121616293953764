import React, { useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link, NavLink } from "react-router-dom";
import Image from "../Image";
import Modal from "../Modal";
import NotifyMe from "../../screens/ComingSoon/NotifyMe";
import Theme from "../Theme";

const CSHeader = ({ headerWide }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <header className={cn(styles.header, { [styles.wide]: headerWide })}>
      <div className={cn("containerCS", styles.container)}>
        <Link className={styles.logo} to="/">
          <Image
            className={styles.picDesktop}
            src="/logo/logo-dark.svg"
            srcDark="/logo/logo-light.svg"
            alt="gula"
          />
          <Image
            className={styles.picMobile}
            src="/logo/logo-icon-dark.svg"
            srcDark="/logo/logo-icon-light.svg"
            alt="gula"
          />
        </Link>
        <div className={styles.wrapper}>
          <div className={styles.info}>Coming Soon</div>

          <div className={cn(styles.wrap)}></div>
          <div className={styles.control}>
            <NavLink
              className={cn("button-black button-small", styles.button)}
              onClick={openModal}
            >
              Notify Me
            </NavLink>
            <Theme className={styles.theme} icon />
          </div>
          <Modal visible={isModalOpen} onClose={closeModal}>
            <NotifyMe onClose={closeModal} />
          </Modal>
        </div>
      </div>
    </header>
  );
};

export default CSHeader;
