import React, { useRef } from "react";
import Main from "./Main";
import Tutorial from "./Tutorial";
import Faq from "./Faq";
import Question from "./Question";

const Support = () => {
  const scrollToRef = useRef(null);
  return (
    <>
      <Main scrollToRef={scrollToRef} />
      <Tutorial />
      <Faq scrollToRef={scrollToRef} />
      <Question scrollToRef={scrollToRef} />
    </>
  );
};

export default Support;
