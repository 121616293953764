import React from "react";
import cn from "classnames";
import styles from "./Values.module.sass";
import Slider from "react-slick";
import Icon from "../../../components/Icon";
import Card from "./Card";

const items = [
  {
    title: "Universal Healthcare Access",
    content:
      "We believe that access to healthcare is not a privilege, but a fundamental human right for everyone. Our mission is to ensure that everyone, regardless of their background, has access to the healthcare services they need.",
    image: "/content/values-pic-1.png",
    image2x: "/content/values-pic-1.png 2x",
  },
  {
    title: "Your Health, Your Data",
    content:
      "Ensuring the security and confidentiality of patient information is paramount. We employ cutting-edge security measures to protect data and uphold the highest standards of privacy.",
    image: "/content/values-pic-2.png",
    image2x: "/content/values-pic-2@2x.png",
  },
  {
    title: "Compassionate Care",
    content:
      "Our commitment to compassionate care ensures that all our patients receive the best treatment in a caring and empathetic environment.",
    image: "/content/values-pic-3.png",
    image2x: "/content/values-pic-3@2x.png",
  },
  {
    title: "Community Well-being",
    content:
      "Our focus extends beyond individual care to encompass community health and wellness, ensuring a healthier environment for all.",
    image: "/content/values-pic-4.png",
    image2x: "/content/values-pic-4@2x.png",
  },
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Values = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="18" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="18" />
      </SlickArrow>
    ),
  };

  return (
    <div className={cn("section-pd section-bg section-mb0", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>What we stand for</h2>
          <div className={styles.info}>
            Values that drive us to deliver the best healthcare experience.
          </div>
        </div>
        <div className={styles.wrapper}>
          <Slider className="news-slider" {...settings}>
            {items.map((x, index) => (
              <Card className={styles.card} item={x} key={index} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Values;
