import React from "react";
import cn from "classnames";
import styles from "./Success.module.sass";
import { Link } from "react-router-dom";
import Icon from "../../../../components/Icon";

const Success = ({ ticketId }) => {
  return (
    <>
      <div className={styles.success}>
        <div className={cn("h3", styles.title)}>
          <Icon name="check" size="60" fill="#58BD7D" />
        </div>
        <div className={styles.info}>
          You successfully submitted a ticket to our support team. We will get
          back to you as soon as possible.
        </div>
        <div className={styles.list}>
          <div className={styles.item}>
            <div className={styles.category}>Ticket ID</div>
            <div className={styles.content}>{ticketId}</div>
          </div>
        </div>
        <div className={styles.btns}>
          <Link className={cn("button-stroke", styles.button)} to="/support">
            FAQ
          </Link>
          <Link className={cn("button", styles.button)} to="/">
            Home
          </Link>
        </div>
      </div>
    </>
  );
};

export default Success;
