import React from "react";
import cn from "classnames";
import styles from "./Contact.module.sass";

const Contact = ({ scrollToRefContact }) => {
  return (
    <div
      className={cn("section-bg section-pd section-mb0", styles.section)}
      ref={scrollToRefContact}
    >
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <div className={styles.stage}>
            We're here to answer your questions
          </div>
          <h1 className={cn("h2", styles.title)}>Press & Media Relations</h1>
          <div className={styles.info}>
            We are committed to responding with transparency, integrity, and
            clarity. In our interactions, we value and anticipate the same
            principles of openness and respect. For all press inquiries, please
            reach out to us at{" "}
            <a href="mailto:press@gulahealth.com" rel="noopener noreferrer">
              press@gulahealth.com
            </a>
            .
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
