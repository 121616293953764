import React from "react";
import cn from "classnames";
import styles from "./LikeButton.module.sass";
import Icon from "../Icon";

const LikeButton = ({ className, active, onClick }) => {
  return (
    <button
      className={cn(
        "button-circle-stroke button-small",
        styles.button,
        { [styles.active]: active },
        className
      )}
      onClick={onClick}
    >
      <Icon name="like" size="24" />
      <Icon name="like" size="24" />
    </button>
  );
};

export default LikeButton;
