export const value = [
  {
    title: "Innovative Healthcare",
    subtitle: "Pioneering the Future",
    description:
      "Driving the forefront of healthcare innovation to deliver advanced, effective, and efficient patient care.",
    color: "#58C27D",
    icon: "clinic",
  },
  {
    title: "Uncompromised Security",
    subtitle: "Protecting What Matters",
    description:
      "Prioritizing the utmost security and confidentiality of patient data, ensuring trust and safety in every interaction.",
    color: "#58C27D",
    icon: "shield",
  },
  {
    title: "Empathetic Service",
    subtitle: "Care with Compassion",
    description:
      "Placing empathy at the core of our services, understanding and addressing the unique needs of each patient.",
    color: "#58C27D",
    icon: "heart-fill",
  },
  {
    title: "Excellence in Quality",
    subtitle: "Setting Higher Standards",
    description:
      "Committing to the highest standards in healthcare delivery, continuously improving and innovating our practices.",
    color: "#58C27D",
    icon: "star-fill",
  },
  {
    title: "Inclusive Care",
    subtitle: "Healthcare for All",
    description:
      "Ensuring accessibility and equity in healthcare, providing exceptional care to every community.",
    color: "#58C27D",
    icon: "globe",
  },
  {
    title: "Collaborative Expertise",
    subtitle: "Together in Health",
    description:
      "Fostering collaboration among healthcare professionals to deliver holistic and integrated care solutions.",
    color: "#58C27D",
    icon: "collab",
  },
  {
    title: "Sustainable Practices",
    subtitle: "Eco-Conscious Healthcare",
    description:
      "Implementing environmentally sustainable practices in healthcare to ensure the wellbeing of future generations.",
    color: "#58C27D",
    icon: "leaf",
  },
  {
    title: "Patient-Centric Technology",
    subtitle: "Technology for Wellbeing",
    description:
      "Leveraging cutting-edge technology to enhance patient experience and outcomes, making healthcare more accessible.",
    color: "#58C27D",
    icon: "user",
  },
  {
    title: "Continuous Learning",
    subtitle: "Evolving with Knowledge",
    description:
      "Embracing continual education and growth to remain at the forefront of medical advancements and patient care.",
    color: "#58C27D",
    icon: "book",
  },
];
