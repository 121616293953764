export const activity = [
  {
    platform: "Web",
    version: "0.0.1",
    notes: "Web portal initial release for clinics.",
    date: "Coming soon",
  },
  {
    platform: "Mobile",
    version: "0.0.1",
    notes: "Mobile app initial release for clients",
    date: "Coming soon",
  },
  {
    platform: "API",
    version: "0.0.1",
    notes: "API initial release for developers",
    date: "Coming soon",
  },
];
