import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";

const Main = ({ scrollToRefContact, scrollToRefCatalog }) => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h1 className={cn("h1", styles.title)}>Newsroom</h1>
          <div className={styles.info}>
            New Releases, Insights, and Developments
          </div>
          <div className={styles.btns}>
            <button
              className={cn("button", styles.button)}
              onClick={() =>
                scrollToRefCatalog.current.scrollIntoView({
                  behavior: "smooth",
                })
              }
            >
              Latest
            </button>
            <button
              className={cn("button-stroke", styles.button)}
              onClick={() =>
                scrollToRefContact.current.scrollIntoView({
                  behavior: "smooth",
                })
              }
            >
              Press Contact
            </button>
          </div>
        </div>
        <div className={styles.video}>
          <img
            className={styles.videoPoster}
            src="/content/newsroom-impact.png"
            alt="Preview"
          />
          {/* <video
            className={styles.videoPlayer}
            src="/content/newsroom.mp4"
            alt="Preview"
            autoPlay
            muted
            loop
          >
            Your browser does not support the video tag.
          </video> */}
        </div>
      </div>
    </div>
  );
};

export default Main;
