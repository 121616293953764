export const privacyPolicy = [
  {
    title: "Privacy Policy",
    subtitle: "Commitment to Your Privacy",
    descriptions: [
      "This policy outlines our handling of personal information collected through Gula Health Inc.'s website and services. We are committed to protecting the privacy of our users and clients.",
      "This policy is continuously assessed against new technologies, business practices, and our users' needs.",
    ],
  },
  {
    title: "Personal Information Collection",
    subtitle: "Types of Information Collected",
    descriptions: [
      "Personal information refers to any information that you voluntarily submit to us and that identifies you personally.",
      "This includes contact information, such as your name, email address, company name, address, phone number, and other information about yourself or your business.",
    ],
  },
  {
    title: "Use of Personal Information",
    subtitle: "How We Use Your Information",
    descriptions: [
      "The information we collect and receive is primarily used to provide our services to you.",
      "We may also use your information for internal business purposes, such as analyzing and managing our service offerings including, identifying usage trends, determining the effectiveness of our promotional campaigns, and to evaluate and improve our services, marketing, and your experience.",
    ],
  },
  {
    title: "Information Sharing",
    subtitle: "With Whom We Share Your Information",
    descriptions: [
      "We may share your personal information with service providers, business partners, and other third parties in accordance with this privacy policy.",
      "We do not sell, rent, or lease our customer lists to third parties.",
    ],
  },
  {
    title: "Security of Your Personal Information",
    subtitle: "Protecting Your Information",
    descriptions: [
      "We use a variety of security technologies and procedures to help protect your personal information from unauthorized access, use, or disclosure.",
      "While we strive to protect your personal information, we cannot guarantee its absolute security.",
    ],
  },
  {
    title: "Data Retention",
    subtitle: "How Long We Keep Your Information",
    descriptions: [
      "We retain personal information for as long as necessary to provide the services you have requested, or for other essential purposes such as complying with legal obligations, resolving disputes, and enforcing our policies.",
    ],
  },
  {
    title: "Your Rights",
    subtitle: "Your Privacy Rights",
    descriptions: [
      "You have the right to access, correct, or delete your personal information and privacy preferences at any time.",
      "You may also have the right to object to or restrict the processing of your personal information, or request data portability.",
    ],
  },
  {
    title: "Policy Updates",
    subtitle: "Changes to This Policy",
    descriptions: [
      "We may update this privacy policy to reflect changes to our information practices.",
      "If we make any material changes, we will notify you by email (sent to the e-mail address specified in your account) or by means of a notice on this website prior to the change becoming effective.",
    ],
  },
];
