import React, { useState, useEffect } from "react";
import TermsLegalLayout from "../../components/TermsLegalLayout";
import styles from "./TermsLegal.module.sass";
import { termsLegal as terms } from "../../data/terms-legal";

const TermsLegal = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const policyTitles = terms.map((terms) => terms.title);

  const handleScroll = () => {
    let newActiveIndex = 0;
    terms.forEach((terms, index) => {
      const element = document.getElementById(`terms-${index}`);
      if (
        element &&
        element.getBoundingClientRect().top < window.innerHeight / 2
      ) {
        newActiveIndex = index;
      }
    });
    setActiveIndex(newActiveIndex);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <TermsLegalLayout
        title="Terms & Legal"
        items={policyTitles}
        activeIndex={activeIndex}
      >
        <div>
          {terms.map((terms, index) => (
            <div key={index} id={`terms-${index}`} className={styles.terms}>
              <div className={styles.title}>{terms.title}</div>
              <div className={styles.subtitle}>{terms.subtitle}</div>
              <ul>
                {terms.descriptions.map((description, descIndex) => (
                  <li className={styles.description} key={descIndex}>
                    {description}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </TermsLegalLayout>
    </>
  );
};

export default TermsLegal;
