import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Main from "./Main";
import Article from "./Article";
import Catalog from "./Catalog";
import { articles } from "../../data/articles";

const NewsroomDetails = () => {
  const { articleName } = useParams();
  const [article, setArticle] = useState(null);

  useEffect(() => {
    const urlFriendlyName = articleName.replace(/-/g, " ").toLowerCase();

    const fetchArticle = () => {
      const storedArticle = localStorage.getItem("selectedArticle");
      if (storedArticle) {
        const parsedArticle = JSON.parse(storedArticle);
        if (parsedArticle.title.toLowerCase() === urlFriendlyName) {
          return parsedArticle;
        }
      }
      return articles.find((a) => a.title.toLowerCase() === urlFriendlyName);
    };

    const foundArticle = fetchArticle();
    if (foundArticle) {
      setArticle(foundArticle);
    } else {
      console.error("Article not found");
      setArticle(null);
    }
  }, [articleName]);

  if (!article) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Main article={article} />
      <Article article={article} />
      <Catalog article={article} excludeTitle={article.title} />
    </>
  );
};

export default NewsroomDetails;
