import React from "react";
import cn from "classnames";
import styles from "./Catalog.module.sass";
import ResearchCatalogCard from "../../../components/ResearchCatalogCard";
import Icon from "../../../components/Icon";
import { articles as initialArticles } from "../../../data/articles";
import { Link } from "react-router-dom";

const Catalog = () => {
  const researchArticles = initialArticles.filter(
    (article) => article.categoryText === "Research"
  );
  const sortedArticles = researchArticles.sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );
  const recentArticles = sortedArticles.slice(0, 9);
  const hasMoreArticles = researchArticles.length > 9;

  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrapper}>
          <div className={styles.head}>
            <h2 className={cn("h2", styles.title)}>
              Research Innovations and Insights
            </h2>
            <div className={cn("info", styles.info)}>
              The future of health monitoring technology using artificaial
              intelligence and biosensors.
            </div>
          </div>
          <div className={styles.list}>
            {recentArticles.map((x, index) => (
              <ResearchCatalogCard
                className={styles.destination}
                item={x}
                key={index}
              />
            ))}
          </div>
          {hasMoreArticles && (
            <Link className={cn("button-stroke", styles.button)} to="/newsroom">
              <span>View more</span>
              <Icon name="arrow-right" size="16" />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default Catalog;
