import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";
import Cards from "./Cards";

const Main = ({ scrollToRef }) => {
  return (
    <div className={cn("section-mb0", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <h1 className={cn("h1", styles.title)}>
            Coming Soon <br></br> for Developers
          </h1>
          <div className={styles.text}>
            Stay tuned in our continuous effort to empower innovation and
            seamless integration in healthcare technology. We're dedicated to
            empowering developers with innovative tools and services to enhance
            healthcare technology.
          </div>
          <div className={styles.btns}>
            <button
              className={cn("button-stroke", styles.button)}
              onClick={() =>
                scrollToRef.current.scrollIntoView({ behavior: "smooth" })
              }
            >
              Sign up for updates
            </button>
          </div>
        </div>
        <div className={styles.bg}>
          <img
            srcSet="/content/developer-main-1@2x.png 2x"
            src="/content/developer-main-1.png"
            alt="Cards"
          />
        </div>
        <Cards className={styles.cards} />
      </div>
    </div>
  );
};

export default Main;
