import React, { useEffect, useRef } from "react";
import cn from "classnames";
import styles from "./Main.module.sass";
import Image from "../../../components/Image";

const gallery = [
  {
    src: "/content/home-pic-1.png",
    srcSet: "/content/home-pic-1@2x.png",
    srcDark: "/content/home-pic-dark-1.png",
    srcSetDark: "/content/home-pic-dark-1@2x.png",
  },
  {
    src: "/content/home-pic-2.png",
    srcSet: "/content/home-pic-2@2x.png",
    srcDark: "/content/home-pic-dark-2.png",
    srcSetDark: "/content/home-pic-dark-2@2x.png",
  },
  {
    src: "/content/home-pic-3.png",
    srcSet: "/content/home-pic-3@2x.png",
    srcDark: "/content/home-pic-dark-3.png",
    srcSetDark: "/content/home-pic-dark-3@2x.png",
  },
  {
    src: "/content/home-pic-4.png",
    srcSet: "/content/home-pic-4@2x.png",
    srcDark: "/content/home-pic-dark-4.png",
    srcSetDark: "/content/home-pic-dark-4@2x.png",
  },
];

const Main = () => {
  const galleryRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const gallery = galleryRef.current;

      if (gallery) {
        const rotationAngle = scrollTop * 0.05;

        gallery.childNodes.forEach((child, index) => {
          child.style.transform = `rotate(${rotationAngle * (index + 1)}deg)`;
        });
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={styles.section}>
      <div className={cn("container", styles.container)}>
        <div className={styles.gallery} ref={galleryRef}>
          {gallery.map((x, index) => (
            <div className={styles.preview} key={index}>
              <Image
                srcSet={`${x.srcSet} 2x`}
                srcSetDark={`${x.srcSetDark} 2x`}
                src={x.src}
                srcDark={x.srcDark}
                alt="Support"
              />
            </div>
          ))}
        </div>
        <h1 className={cn("h2", styles.title)}>Accessible HealthCare</h1>
        <div className={styles.info}>
          We believe that access to good quality healthcare is a fundamental
          human right. We are committed to making healthcare accessible to
          everyone, everywhere.
        </div>
      </div>
    </div>
  );
};

export default Main;
