import React, { useEffect, useRef } from "react";
import cn from "classnames";
import styles from "./PrivacyLayout.module.sass";

const PrivacyLayout = ({ title, items, children, activeIndex }) => {
  const sidebarRef = useRef(null);
  const wrapperRef = useRef(null);

  const handleScroll = () => {
    if (sidebarRef.current && wrapperRef.current) {
      const sidebarRect = sidebarRef.current.getBoundingClientRect();
      const wrapperRect = wrapperRef.current.getBoundingClientRect();
      const sidebarBottom = sidebarRect.bottom;
      const wrapperBottom = wrapperRect.bottom;

      const difference = wrapperBottom - sidebarBottom;

      if (difference < 0) {
        const translateY = Math.min(0, difference + 70);
        sidebarRef.current.style.transform = `translateY(${translateY}px)`;
      } else {
        sidebarRef.current.style.transform = "translateY(0px)";
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className={styles.bidding}>
      <div className={styles.head}>
        <div className={cn("container", styles.container)}>
          <h2 className={cn("h2", styles.title)}>{title}</h2>
          <div className={styles.info}>
            Last Updated <strong>May 1st, 2024</strong>
          </div>
        </div>
      </div>

      <div className={styles.body}>
        <div className={cn("container", styles.container)}>
          <div className={styles.sidebar} ref={sidebarRef}>
            <div className={styles.steps}>
              {items.map((x, index) => (
                <div
                  className={cn(
                    styles.step,
                    { [styles.next]: index === activeIndex },
                    { [styles.active]: index < activeIndex }
                  )}
                  key={index}
                >
                  <div className={styles.number}>{index + 1}</div>
                  {x}
                </div>
              ))}
            </div>
          </div>
          <div className={styles.wrapper} ref={wrapperRef}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyLayout;
