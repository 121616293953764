import React from "react";
import cn from "classnames";
import styles from "./Tutorial.module.sass";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Icon from "../../../components/Icon";
import { articles as initialArticles } from "../../../data/articles";

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Education = () => {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
    responsive: [
      {
        breakpoint: 1179,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const tutorialArticles = initialArticles
    .filter((article) => article.categoryText === "Tutorial")
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .slice(0, 9);

  const handleCardClick = (selectedArticle) => {
    localStorage.setItem("selectedArticle", JSON.stringify(selectedArticle));
  };
  return (
    <div className={cn("section", styles.education)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.info}>
          Before asking, check these resources to see if they can help you.
        </div>
        <div className={styles.wrapper}>
          <Slider
            className={cn("education-slider", styles.slider)}
            {...settings}
          >
            {tutorialArticles.map((article, index) => (
              <Link
                className={styles.item}
                key={index}
                to={`/newsroom-details/${article.title
                  .toLowerCase()
                  .replace(/\s+/g, "-")}`}
                onClick={() => handleCardClick(article)}
              >
                <div className={cn("education-preview", styles.preview)}>
                  {/* <div
                    className={cn(
                      "category",
                      {
                        "category-purple": article.category === "purple",
                      },
                      {
                        "category-green": article.category === "green",
                      },
                      {
                        "category-red": article.category === "red",
                      },
                      styles.category
                    )}
                  >
                    {article.categoryText}
                  </div> */}
                  <img
                    srcSet={`${article.cardImage2x} 2x`}
                    src={article.cardImage}
                    alt="Education"
                  />
                </div>
                <div className={styles.body}>
                  <div className={styles.title}>{article.title}</div>
                </div>
              </Link>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Education;
