import React, { useState } from "react";
import styles from "./NotifyMe.module.sass";
import Form from "../../../components/Form";
import cn from "classnames";
import axios from "axios";

const NotifyMe = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [displayMessage, setDisplayMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setDisplayMessage("");
    try {
      const requestBody = {
        email: email,
        type: "announcement",
        source: "gulahealth-website-coming-soon",
      };
      const response = await axios.post(
        "https://0gbi0gwcj1.execute-api.us-east-2.amazonaws.com/dev/gulaLead",
        requestBody
      );
      const responseBody = JSON.parse(response.data.body);
      console.log(responseBody);
      if (responseBody.message.includes("Duplicate email")) {
        setIsSuccess(false);
        setDisplayMessage(responseBody.message);
      } else {
        setIsSuccess(true);
        setDisplayMessage(
          "You have successfully subscribed to our newsletter!"
        );
        setTimeout(onClose, 2000);
      }
    } catch (error) {
      console.error(error);
      setDisplayMessage("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <div className={styles.container}>
      <div className={styles.col}>
        <div className={styles.headText}>Coming Soon</div>
        <h1 className={cn("h3", styles.modalTitle)}>
          Join our newsletter and be the first to know when we launch.
        </h1>
        <div className={styles.info}>
          Sign up and we'll send you the latest news about Gula Health.
        </div>
        {isSuccess ? (
          <div className={styles.successMessage}>
            <strong>{displayMessage}</strong>
          </div>
        ) : (
          <>
            <Form
              className={styles.form}
              value={email}
              setValue={setEmail}
              onSubmit={handleSubmit}
              placeholder="Enter your email"
              type="email"
              name="email"
              icon="arrow-next"
              loading={isLoading}
            />
            {displayMessage && (
              <div className={styles.errorMessage}>
                <strong>{displayMessage}</strong>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default NotifyMe;
