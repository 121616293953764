import React from "react";
import cn from "classnames";
import styles from "./Services.module.sass";
import Item from "./Item";

const services = [
  {
    image: "/content/about-services-integrated.svg",
    title: "Seamless Healthcare Integration",
    content:
      "Discover a unified approach to health with our expansive network of medical professionals and services, tailored to your unique health journey.",
  },
  {
    image: "/content/lightning.svg",
    title: "24/7 Support",
    content:
      "With round-the-clock support, assistance is always just a moment away, ensuring your peace of mind.",
  },
  {
    image: "/content/about-services-medical.svg",
    title: "Healthcare at Your Fingertips",
    content:
      "Book appointments with ease and flexibility. We adapt to your schedule, offering timely healthcare access that fits seamlessly into your lifestyle.",
  },
];

const Services = () => {
  return (
    <div className={cn("section-pd section-mb0", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <div className={cn("stage", styles.stage)}>
            Redefining Healthcare Access
          </div>
          <h2 className={cn("h2", styles.title)}>
            Services Tailored for Your Well-being
          </h2>
        </div>
        <div className={styles.list}>
          {services.map((x, index) => (
            <Item className={styles.item} item={x} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;
