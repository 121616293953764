import React from "react";
import Main from "./Main";
import Vision from "./Vision";
import Browse from "./Browse";
import Values from "./Values";
import Services from "./Services";
import Testimonials from "./Testimonials";
import Contact from "./Contact";

const About = () => {
  return (
    <>
      <Main />
      <Vision />
      <Browse />
      <Values />
      <Services />
      {/* <Testimonials /> */}
      <Contact />
    </>
  );
};

export default About;
