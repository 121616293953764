import React from "react";
import cn from "classnames";
import styles from "./ResearchPhotos.module.sass";
import Icon from "../Icon";
import { Link } from "react-router-dom";
import Masonry from "react-masonry-css";

const breakpointCols = {
  default: 2,
  768: 1,
};

const gallery = [
  "/content/research-gallery-1.png",
  "/content/research-gallery-2.png",
  "/content/research-gallery-3.png",
  "/content/research-gallery-4.png",
  "/content/research-gallery-5.png",
  "/content/research-gallery-6.png",
];

const ResearchPhotos = () => {
  return (
    <>
      <div className={cn("section-mb80", styles.section)}>
        <div className={cn("container", styles.container)}>
          <Link
            className={cn("button-stroke button-small", styles.button)}
            to="/research"
          >
            <Icon name="arrow-left" size="10" />
            <span>Go back</span>
          </Link>
          <div className={styles.head}>
            <h2 className={cn("h2", styles.title)}>Research Gallery</h2>
            <div className={cn("info", styles.info)}>
              Navigating the Future of Health Monitoring Technology
            </div>
          </div>

          {gallery.map(
            (x, index) =>
              index === 0 && (
                <div className={styles.preview} key={index}>
                  <img src={x} alt="Nature" />
                </div>
              )
          )}
          <div className={styles.inner}>
            <Masonry
              className={styles.grid}
              columnClassName={styles.column}
              breakpointCols={breakpointCols}
            >
              {gallery.map(
                (x, index) =>
                  index > 0 && (
                    <div className={styles.preview} key={index}>
                      <img src={x} alt="Nature" />
                    </div>
                  )
              )}
            </Masonry>
          </div>
          <div className={styles.foot}>
            <Link
              to="/research"
              className={cn("button-circle-stroke button-small", styles.button)}
            >
              <Icon name="close" size="24" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResearchPhotos;
